import * as React from "react";
import * as ReactDOM from "react-dom";
import {Provider} from "react-redux";

import {applyMiddleware, createStore} from "redux";
// @ts-ignore
import axiosMiddleware from "redux-axios-middleware";
import {composeWithDevTools} from "redux-devtools-extension";
import "styles/main.scss";
import {initialState, reducers} from "./reducers";
import axios, {AxiosResponse} from "axios";
import AppComponent from "./components/AppComponent";
import {persistenceMiddleware, restoreState} from "./tools/StateRestorer";
import {clearSession} from "./actions";

export const httpClient = axios.create({
    baseURL: process.env.API_ENDPOINT,
    responseType: "json",
    withCredentials: true
});

export const createCoreStore = () => {

    const state = restoreState(initialState);

    return createStore(
        reducers,
        state,
        composeWithDevTools(
            applyMiddleware(
                axiosMiddleware(httpClient),
                persistenceMiddleware
            )
        )
    );
};

const store = createCoreStore();

httpClient.interceptors.response.use((resp: AxiosResponse) => {
    return resp;
}, (error: any) => {
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
        store.dispatch(clearSession());
    }
    return Promise.reject(error);
});

ReactDOM.render(
    <Provider store={store}>
        <AppComponent/>
    </Provider>,
    document.getElementById("app") as HTMLElement
);
