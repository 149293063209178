import * as React from "react";
import Notifier, { NotifierVariant } from "../../tools/Notifier";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import { List } from "immutable";
import { networkActions } from "../../actions/network";
import RootState from "../../states/RootState";
import { connect } from "react-redux";
import { BaseComponent } from "../BaseComponent";
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import FirmwareCloudUpdateItem from "../../models/FirmwareCloudUpdateItem";
import { getState } from "../../reducers/FirmwareCloudUpdateJournalReducer";
import moment = require("moment");

const dateFormat = (date: Date) => moment(date).format("DD.MM.YYYY HH:mm:ss");

interface FirmwaresCloudUpdateJournalProps {
    readonly env: string;
    readonly progress: boolean;
    readonly firmwares: List<FirmwareCloudUpdateItem>;
    readonly offset: number;
    readonly limit: number;
    readonly count: number;
    readonly errorMessage: string | null;
}

const NULL = "NULL";

const renderNullableTextCell = (value: string, tableMeta: any, updateValue: any): string | React.ReactNode => {
    if (value === NULL) {
        return <span className="tableNullCell">{value}</span>;
    } else {
        return value;
    }
};

type FirmwareTableColumn = MUIDataTableColumn & {
    dataGetter(firmware: FirmwareCloudUpdateItem): any;
    readonly sortIds: ReadonlyArray<string>;
};

/* tslint:disable:readonly-array */
const tableColumns: FirmwareTableColumn[] = [
    {
        name: "Job Id",
        sortIds: ["jobId"],
        dataGetter: (firmware: FirmwareCloudUpdateItem) => firmware.jobId,
        options: {
            filter: false,
        }
    },
    {
        name: "Type and Version",
        sortIds: ["version"],
        dataGetter: (firmware: FirmwareCloudUpdateItem) => {
            if (firmware.version === null) {
                return "Ptags"
            } else {
                return firmware.deviceType + "/" + firmware.version
            }
        },
        options: {
            filter: false,
        }
    },
    {
        name: "Create Date",
        sortIds: ["createdAt"],
        dataGetter: (firmware: FirmwareCloudUpdateItem) => firmware.createdAt ? dateFormat(firmware.createdAt) : NULL,
        options: {
            filter: false,
            customBodyRender: renderNullableTextCell,
        }
    },
    {
        name: "Finish Date",
        sortIds: ["finishedAt"],
        dataGetter: (firmware: FirmwareCloudUpdateItem) => firmware.finishedAt ? dateFormat(firmware.finishedAt) : NULL,
        options: {
            filter: false,
            customBodyRender: renderNullableTextCell,
        }
    },
    {
        name: "Processed Firmware Count",
        sortIds: ["processedFirmwaresCount"],
        dataGetter: (firmware: FirmwareCloudUpdateItem) => firmware.processedFirmwaresCount,
        options: {
            filter: false,
        }
    },
    {
        name: "Processed vTag Count",
        sortIds: ["processedVtagsCount"],
        dataGetter: (firmware: FirmwareCloudUpdateItem) => firmware.processedVtagsCount,
        options: {
            filter: false,
        }
    },
    {
        name: "Total Firmware Count",
        sortIds: ["firmwaresCount"],
        dataGetter: (firmware: FirmwareCloudUpdateItem) => firmware.firmwaresCount,
        options: {
            filter: false,
        }
    },
    {
        name: "Total vTag Count",
        sortIds: ["vTagsCount"],
        dataGetter: (firmware: FirmwareCloudUpdateItem) => firmware.vTagsCount,
        options: {
            filter: false,
        }
    },
    {
        name: "Ptags Count",
        sortIds: ["ptagsCount"],
        dataGetter: (firmware: FirmwareCloudUpdateItem) => firmware.pTagsCount,
        options: {
            filter: false
        }
    },
];

export class FirmwareCloudUpdateJournalComponent extends BaseComponent<FirmwaresCloudUpdateJournalProps, any> {

    // tslint:disable-next-line:max-params
    private loadFirmwares(offset: number, limit: number): void {
        this.props.dispatch(networkActions.getFirmwareUpdateCloudList(this.props.env, offset, limit));
    }

    public componentWillMount(): void {
        if (this.props.env) {
            this.props.dispatch(networkActions.getFirmwareUpdateCloudStats(this.props.env));
            this.loadFirmwares(this.props.offset, this.props.limit);
        }
    }

    public componentDidUpdate(prevProps: Readonly<FirmwaresCloudUpdateJournalProps>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.env !== this.props.env) {
            this.props.dispatch(networkActions.getFirmwareUpdateCloudStats(this.props.env));
            this.loadFirmwares(this.props.offset, this.props.limit);
        }
    }

    public render(): any {
        const options: MUIDataTableOptions = {
            filterType: "dropdown",
            responsive: "scrollFullHeight",
            selectableRows: "none",
            pagination: this.props.limit < this.props.count,
            sort: false,
            filter: false,
            print: false,
            rowsPerPage: this.props.limit,
            rowsPerPageOptions: [25, 50, 100, 150, 200, 250, 300, 400, 500, 1000],
            count: this.props.count,
            serverSide: true,
            downloadOptions: {
                filename: "report.csv"
            },
            onTableChange: (action: any, tableState: MUIDataTableState) => {
                if (action === "changePage" || action === "changeRowsPerPage") {
                    this.loadFirmwares(tableState.page * tableState.rowsPerPage, tableState.rowsPerPage);
                }
            },
        };

        const error = this.props.errorMessage
            ? <Notifier
                message={this.props.errorMessage}
                open={true}
                variant={NotifierVariant.ERROR}
            />
            : null;
        const tableData: any = this.props.firmwares.map((value: FirmwareCloudUpdateItem): ReadonlyArray<any> => {
            return tableColumns.map((column: any) => column.dataGetter(value));
        });
        return <main className="firmwares">
            <div className="progressContainer">
                {this.props.progress && <LinearProgress />}
            </div>
            <MUIDataTable
                title={null}
                columns={tableColumns}
                data={tableData}
                options={options}
            />
            {error}
        </main>;
    }
}

export default connect((state: RootState, ownProps: FirmwaresCloudUpdateJournalProps): FirmwaresCloudUpdateJournalProps => {
    const firmwaresState = getState(state);
    return {
        env: ownProps.env,
        progress: firmwaresState.progress,
        firmwares: firmwaresState.firmwares,
        offset: firmwaresState.offset,
        limit: firmwaresState.limit,
        count: firmwaresState.count,
        errorMessage: firmwaresState.error ? firmwaresState.error.message : null
    };
})(FirmwareCloudUpdateJournalComponent);
