import { handleActions } from "redux-actions";
import AuthAuditState from "../states/AuthAuditState";
import RootState from "../states/RootState";
import { reduceAxiosFail } from "../states/ErrorState";
import { List } from "immutable";

const defaultState: AuthAuditState = {
    items: List(),
    count: 0,
    offset: 0,
    limit: 25,
    progress: false,
    error: null,
};

export const AuthAuditReducer = handleActions<AuthAuditState>({
    GET_AUDIT_ITEMS: (state: AuthAuditState, action: any): AuthAuditState => ({
        items: state.items,
        count: state.count,
        offset: action.payload.request.params.offset,
        limit: action.payload.request.params.limit,
        progress: true,
        error: null
    }),
    GET_AUDIT_ITEMS_SUCCESS: (state, action: any): AuthAuditState => {
        return {
            items: List.of(...action.payload.data),
            count: state.count,
            offset: state.offset,
            limit: state.limit,
            progress: false,
            error: null
        };
    },
    GET_AUDIT_ITEMS_FAIL: (state, action: any): AuthAuditState => ({
        items: defaultState.items,
        count: defaultState.count,
        offset: defaultState.offset,
        limit: defaultState.limit,
        progress: defaultState.progress,
        error: reduceAxiosFail(state, action)
    }),
    GET_AUDIT_STATS: (state, action: any): AuthAuditState => ({
        items: state.items,
        count: 0,
        offset: defaultState.offset,
        limit: defaultState.limit,
        progress: true,
        error: null,
    }),
    GET_AUDIT_STATS_SUCCESS: (state, action: any): AuthAuditState => ({
        items: state.items,
        count: action.payload.data.count,
        offset: state.offset,
        limit: state.limit,
        progress: state.progress,
        error: null,
    }),
}, defaultState)


export const getState = (state: RootState): AuthAuditState => state.components!.authState;
