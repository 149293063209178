import {Action} from "redux";

export default interface ErrorState {
    readonly message: string;
    readonly action?: Action<any>;
}

export const reduceAxiosFail = (state: any, action: any): ErrorState => {
    const message = () => {
        // Legacy
        const response = action.error.response;
        if (response) {
            const data = response.data
            if (data) {
                return (data.errorMessage) ? data.errorMessage : data.message;
            } else {
                return response.statusText;
            }
        } else {
            return action.error.data;
        }
    };
    return {
        message: message(),
        action: action
    };
};
