import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FilterIcon from "@material-ui/icons/FilterList";
import PopupState, {bindPopover, bindTrigger} from "material-ui-popup-state";
import {Popover, Typography} from "@material-ui/core";

export default class PurchasesFilterToolbar extends React.PureComponent<any> {

    public render(): any {
        return <PopupState variant="popover" popupId="purchases-filter-popover">
            {(popupState: any) => (
                <React.Fragment>
                    <Tooltip title={"Filter"} {...bindTrigger(popupState)}>
                        <IconButton>
                            <FilterIcon/>
                        </IconButton>
                    </Tooltip>
                    <Popover
                        {...bindPopover(popupState)}
                        elevation={2}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >
                        <div className="filter">
                            <div className="header">
                                <Typography>Here will be filters</Typography>
                            </div>
                        </div>
                    </Popover>
                </React.Fragment>
            )}
        </PopupState>;
    }

}

// export default withStyles({})(PurchasesFilterToolbar);
