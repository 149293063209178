import { createActions } from "redux-actions";
import * as Qs from "qs";

export class QuerySort {

    public readonly id: string;
    public readonly direction: string;

    constructor(id: string, direction: string) {
        this.id = id;
        this.direction = direction;
    }

    public toQueryString(): string {
        return `${this.id}.${this.direction}`;
    }
}

export const networkActions = createActions({
    GET_USER: () => {
        return {
            request: {
                method: "GET",
                url: "/api/user/info"
            }
        };
    },
    LOGOUT: () => {
        return {
            request: {
                method: "GET",
                url: "/auth/logout"
            }
        };
    },
    GET_LICENSING_INFO: (env: string) => {
        return {
            request: {
                method: "GET",
                url: `/api/licensing/${env}/info`,
            }
        };
    },
    // tslint:disable-next-line:max-params
    GET_PURCHASES_LIST: (env: string, offset: number, limit: number, sorts: ReadonlyArray<QuerySort>, search: string) => {
        return {
            request: {
                method: "GET",
                url: `/api/licensing/${env}/purchases/list`,
                params: {
                    offset: offset,
                    limit: limit,
                    sort: sorts.map((sort: QuerySort) => sort.toQueryString()),
                    mastercard: search
                },
                paramsSerializer: (params: any) => {
                    return Qs.stringify(params, { arrayFormat: "repeat" });
                },
            }
        };
    },
    // tslint:disable-next-line:max-params
    GET_FREE_PURCHASES_LIST: (env: string, offset: number, limit: number, sorts: ReadonlyArray<QuerySort>, search: string) => {
        return {
            request: {
                method: "GET",
                url: `/api/licensing/${env}/free_purchases/list`,
                params: {
                    offset: offset,
                    limit: limit,
                    sort: sorts.map((sort: QuerySort) => sort.toQueryString()),
                    mastercard: search
                },
                paramsSerializer: (params: any) => {
                    return Qs.stringify(params, { arrayFormat: "repeat" });
                },
            }
        };
    },
    GET_PURCHASES_STATS: (env: string) => {
        return {
            request: {
                method: "GET",
                url: `/api/licensing/${env}/purchases/stats`,
            }
        };
    },
    GET_FREE_PURCHASES_STATS: (env: string) => {
        return {
            request: {
                method: "GET",
                url: `/api/licensing/${env}/free_purchases/stats`,
            }
        };
    },
    REMOVE_FREE_PURCHASE: (env: string, productId: number) => {
        return {
            request: {
                method: "POST",
                url: `/api/licensing-admin/${env}/purchase/remove_free_purchase`,
                data: {
                    id: productId
                }
            }
        };
    },
    TRACK_PURCHASE: (env: string, productId: number, token: string, price: number, currency: string) => {
        return {
            request: {
                method: "POST",
                url: `/api/licensing-admin/${env}/purchase/track_purchase`,
                data: {
                    id: productId,
                    googleToken: token,
                    price: price,
                    currency: currency
                }
            }
        };
    },
    CLEAR_PURCHASE_INFO: (env: string, purchaseId: number) => {
        return {
            request: {
                method: "POST",
                url: `/api/licensing-admin/${env}/purchase/clear_info`,
                data: {
                    id: purchaseId
                }
            }
        };
    },
    CREATE_FREE_PURCHASE: (env: string, masterCard: string, productId: string, expiresDate: number, comment: string) => {
        return {
            request: {
                method: "POST",
                url: `/api/licensing-admin/${env}/purchase/create`,
                data: {
                    masterCardNumber: masterCard,
                    productId: productId,
                    expiresDate: expiresDate,
                    comment: comment
                }
            }
        };
    },
    RESTORE_MOBILE_KEY_DEFAULT_PASSWORD: (masterCardNumber: string, masterCardUid: string) => {
        return {
            request: {
                method: "POST",
                url: `/api/tools/mobilekey/restore_default_password`,
                data: {
                    masterCardNumber: masterCardNumber,
                    masterCardUid: masterCardUid
                }
            }
        };
    },
    RECREATE_MOBILE_KEY_USER: (mastercardNumber: string, mastercardUid: string) => {
        return {
            request: {
                method: "POST",
                url: `/api/tools/mobilekey/recreate_user`,
                data: {
                    masterCardNumber: mastercardNumber,
                    masterCardUid: mastercardUid
                }
            }
        };
    },
    GET_OPENAPI_FILE: () => {
        return {
            request: {
                method: "GET",
                url: "/admin-session/openapi.yaml",
                responseType: "text",
            }
        };
    },
    // tslint:disable-next-line:max-params
    GET_ERRORS: (type: string, env: string) => {
        return {
            request: {
                method: "GET",
                url: `/api/errors/${env}/${type}/list`,
            },
            meta: {
                type: type
            }
        };
    },
    GET_AUTH_INFO: (env: string) => {
        return {
            request: {
                method: "GET",
                url: `/api/auth/${env}/info`,
            }
        };
    },
    GET_AUDIT_ITEMS: (env: string, offset: number, limit: number) => {
        return {
            request: {
                method: "GET",
                params: {
                    offset: offset,
                    limit: limit,
                },
                url: `/api/auth/${env}/audit/items`,
            }
        };
    },
    GET_AUDIT_STATS: (env: string) => {
        return {
            request: {
                method: "GET",
                url: `/api/auth/${env}/audit/stats`,
            }
        };
    },
    GET_FWU_INFO: (env: string) => {
        return {
            request: {
                method: "GET",
                url: `/api/fwu/${env}/info`,
            }
        };
    },
    // tslint:disable-next-line:max-params
    GET_FIRMWARES_LIST: (env: string, offset: number, limit: number, sorts: ReadonlyArray<QuerySort>, search: string) => {
        return {
            request: {
                method: "GET",
                url: `/api/fwu/${env}/firmwares/list`,
                params: {
                    offset: offset,
                    limit: limit,
                    sort: sorts.map((sort: QuerySort) => sort.toQueryString()),
                    search: search
                },
                paramsSerializer: (params: any) => {
                    return Qs.stringify(params, { arrayFormat: "repeat" });
                },
            }
        };
    },
    GET_FIRMWARES_STATS: (env: string) => {
        return {
            request: {
                method: "GET",
                url: `/api/fwu/${env}/firmwares/stats`,
            }
        };
    },
    GET_FIRMWARE_UPDATE_CLOUD_LIST: (env: string, offset: number, limit: number) => {
        return {
            request: {
                method: "GET",
                url: `/api/fwu/${env}/firmwares/cloud_update_list`,
                params: {
                    offset: offset,
                    limit: limit
                },
                paramsSerializer: (params: any) => {
                    return Qs.stringify(params, { arrayFormat: "repeat" });
                },
            }
        };
    },
    GET_FIRMWARE_UPDATE_CLOUD_STATS: (env: string) => {
        return {
            request: {
                method: "GET",
                url: `/api/fwu/${env}/firmwares/cloud_update_stats`,
            }
        };
    },
    GET_PTAGS_STATS: (env: string, search: string) => {
        return {
            request: {
                method: "GET",
                url: `/api/fwu/${env}/firmwares/ptags`,
                params: {
                    search: search
                },
                paramsSerializer: (params: any) => {
                    return Qs.stringify(params, { arrayFormat: "repeat" });
                },
            }
        };
    },
    ADD_MOBILE_KEY_USER: (env: string, mastercard: string, ownerAccountId: string) => {
        return {
            request: {
                method: "PUT",
                url: `/api/mobilekey/${env}/users`,
                data: {
                    mastercard: mastercard,
                    ownerAccountId: ownerAccountId
                }
            }
        };
    },
    GET_SOFTWARE_LICENSES_LIST: (env: string, offset: number, limit: number, sorts: ReadonlyArray<QuerySort>, search: string) => {
        return {
            request: {
                method: "GET",
                url: `/api/software_licensing/${env}/license/list`,
                params: {
                    offset: offset,
                    limit: limit,
                    sort: sorts.map((sort: QuerySort) => sort.toQueryString()),
                    mastercard: search,
                    esid: search
                },
                paramsSerializer: (params: any) => {
                    return Qs.stringify(params, { arrayFormat: "repeat" });
                },
            }
        };
    },
    GET_SOFTWARE_LICENSES_LIST_STATS: (env: string) => {
        return {
            request: {
                method: "GET",
                url: `/api/software_licensing/${env}/license/stats`,
            }
        };
    },
    GENERATE_SOFTWARE_LICENSE: (env: string, esid: string) => {
        return {
            request: {
                method: "GET",
                url: `/api/software_licensing/${env}/license/generate_license_file`,
                params: {
                    esid: esid
                }
            }
        };
    }
});
