import {SvgIcon} from "@material-ui/core";
import * as React from "react";

export default class LicensingIconComponent extends React.Component<{}, {}> {
    public render(): any {
        return <SvgIcon viewBox="0 0 20 20" {...this.props}>
            <path fill="#000" d="M18.333 15.833h-5V12.5H11.1c-.95 2.017-3 3.333-5.267 3.333C2.617 15.833 0 13.217 0 10s2.617-5.833 5.833-5.833c2.267 0 4.309 1.316 5.267 3.333H20v5h-1.667v3.333zM15 14.167h1.667v-3.334h1.666V9.167H9.95l-.192-.559C9.175 6.95 7.592 5.833 5.833 5.833c-2.3 0-4.166 1.867-4.166 4.167s1.866 4.167 4.166 4.167c1.759 0 3.342-1.117 3.925-2.775l.192-.559H15v3.334zM5.833 12.5c-1.375 0-2.5-1.125-2.5-2.5s1.125-2.5 2.5-2.5 2.5 1.125 2.5 2.5-1.125 2.5-2.5 2.5zm0-3.333C5.375 9.167 5 9.542 5 10c0 .458.375.833.833.833.459 0 .834-.375.834-.833 0-.458-.375-.833-.834-.833z"/>
        </SvgIcon>;
    }
}
