// import {gaTracker} from "core";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";

interface BaseComponentProps {
    readonly children?: React.ReactNode;
    readonly dispatch: Dispatch;
}

type DefaultProps = BaseComponentProps & RouteComponentProps<any>;

export class BaseComponent<P extends any, S extends any> extends React.Component<P & DefaultProps, S> {

}
