import {BaseComponent} from "../BaseComponent";
import * as React from "react";
import {CircularProgress, Tab, Tabs} from "@material-ui/core";
import {appBarActions} from "../../actions/appbar";
import {connect} from "react-redux";
import {List} from "immutable";
import RootState from "../../states/RootState";
import Environment from "../../models/Environment";
import DashboardComponent from "../common/DashboardComponent";
import {networkActions} from "../../actions/network";
import {FIRMWARES_DASHBOARD} from "../../states/DashboardState";
import FirmwaresListComponent from "./FirmwaresListComponent";
import FirmwareCloudUpdateJournalComponent from "./FirmwareCloudUpdateJournalComponent";

interface FirmwaresProps {
    readonly env: Environment;
}

interface FirmwaresState {
    readonly tabIndex: number;
}

const envs = List([
    {
        id: "dev",
        title: "Development"
    },
    {
        id: "prod",
        title: "Production"
    }
]);

const tabs: ReadonlyArray<any> = [
    {
        path: "dashboard",
        label: "Dashboard",
        component: DashboardComponent
    },
    {
        path: "firmwares",
        label: "Firmwares",
        component: FirmwaresListComponent
    },
    {
        path: "journal",
        label: "Cloud Update Journal",
        component: FirmwareCloudUpdateJournalComponent
    }
];

export class FirmwaresComponent extends BaseComponent<FirmwaresProps, FirmwaresState> {

    public readonly state: FirmwaresState = {
        tabIndex: 0,
    };

    public componentWillMount(): void {
        let env = this.props.match.params.env;
        let tab = this.props.match.params.tab;
        if (!env) {
            env = envs.first({id: "dev"}).id;
        }
        if (!tab) {
            tab = tabs[0].path;
        }
        const location = `/firmwares/${env}/${tab}`;
        if (this.props.history.location.pathname !== location) {
            this.props.history.replace(location);
        }
        this.props.dispatch(appBarActions.setTitle("Firmwares"));
        this.props.dispatch(appBarActions.setEnvs(envs));
        this.props.dispatch(appBarActions.selectEnv(envs.find((value) => value.id === env)));
        this.props.dispatch(networkActions.getFwuInfo(env));
    }

    public componentDidUpdate(prevProps: Readonly<FirmwaresProps>, prevState: Readonly<FirmwaresState>, snapshot?: any): void {
        const tab = this.props.match.params.tab;
        const env = this.props.match.params.env;
        if (env && this.props.env.id !== env) {
            this.props.history.replace(`/firmwares/${this.props.env.id}/${tab}`);
            this.props.dispatch(networkActions.getFwuInfo(env));
        }
    }

    public render(): any {
        const handleTabChange = (event: any, value: any) => {
            this.props.history.replace(tabs[value].path);
        };
        let tabIndex = tabs.findIndex((value) => value.path === this.props.match.params.tab);
        if (tabIndex < 0) {
            tabIndex = 0;
        }
        return <main className={"content server"}>
            <Tabs
                value={tabIndex}
                indicatorColor="secondary"
                textColor="secondary"
                onChange={handleTabChange}
            >
                {
                    tabs.map((value) => {
                        return <Tab value={value.value} label={value.label} className={"tab"}/>;
                    })
                }
            </Tabs>
            <div className={"content"}>
                {this.props.env ? React.createElement(tabs[tabIndex].component, {
                        env: this.props.env!.id,
                        type: FIRMWARES_DASHBOARD,
                        errorsProject: `firmwares-${this.props.env!.id}`
                    }) :
                    <CircularProgress/>}
            </div>
        </main>;
    }
}

export default connect((state: RootState): FirmwaresProps => {
    return {
        env: state.appBar.selectedEnvironment!
    };
})(FirmwaresComponent);
