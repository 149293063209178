import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, withStyles } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { Form, Formik, FormikValues } from "formik";
import * as React from "react";
import * as yup from "yup";

interface RecreateMobileKeyUserProps {
    readonly open: boolean;
    readonly classes?: any;

    onClose(): void;
    onSubmit(mastercardNumber: string, mastercardUid: string): void;
}

const form = {
    mastercardNumber: "",
    mastercardUid: ""
};

const formValidationSchema = yup.object().shape({
    mastercardNumber: yup.string()
        .required("Mastercard number is required.")
        .matches(/^[0-9]*$/, "Wrong format.")
        .length(14, "Mastercard number should be 14 digits."),
    mastercardUid: yup.string()
        .required("Mastercard UID is required.")
        .matches(/^[0-9A-F]*$/, "Wrong format.")
        .length(14, "Mastercard UID mast be 14 symbols.")
});

class RecreateMobileKeyUserComponent extends React.Component<RecreateMobileKeyUserProps> {

    private readonly handleClose = () => {
        this.props.onClose();
    }

    private readonly handleSubmit = (values: FormikValues) => {
        this.props.onSubmit(values.mastercardNumber, values.mastercardUid);
    }

    public render(): any {
        return <Dialog open={true} aria-labelledby="form-dialog-title" onClose={this.handleClose}>
            <DialogTitle id="form-dialog-title">Recreate MobileKey User</DialogTitle>
            <Formik
                initialValues={form}
                validationSchema={formValidationSchema}
                onSubmit={this.handleSubmit}
            >
                {(formik) => (
                    <Form id="recreate-mobilekey-user">
                        <DialogContent>
                            <DialogContentText>
                                To recreate MobileKey user enter Mastercard number and Mastercard UID
                            </DialogContentText>
                            <TextField
                                InputLabelProps={{
                                    classes: {
                                        root: this.props.classes.formLabelRoot,
                                        focused: this.props.classes.formLabelFocused,
                                        error: this.props.classes.erroredLabel
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        underline: this.props.classes.inputUnderline,
                                        error: this.props.classes.error
                                    },
                                }}
                                value={formik.values.mastercardNumber}
                                onChange={formik.handleChange}
                                error={formik.touched.mastercardNumber && Boolean(formik.errors.mastercardNumber)}
                                helperText={formik.touched.mastercardNumber && formik.errors.mastercardNumber}
                                autoFocus
                                margin="dense"
                                id="mastercardNumber"
                                name="mastercardNumber"
                                label="Mastercard Number"
                                type="text"
                                fullWidth
                            />
                            <TextField
                                InputLabelProps={{
                                    classes: {
                                        root: this.props.classes.formLabelRoot,
                                        focused: this.props.classes.formLabelFocused,
                                        error: this.props.classes.erroredLabel
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        underline: this.props.classes.inputUnderline,
                                        error: this.props.classes.error
                                    },
                                }}
                                inputProps={{
                                    style: {
                                        textTransform: "uppercase"
                                    }
                                }}
                                value={formik.values.mastercardUid}
                                onChange={formik.handleChange}
                                error={formik.touched.mastercardUid && Boolean(formik.errors.mastercardUid)}
                                helperText={formik.touched.mastercardUid && formik.errors.mastercardUid}
                                margin="dense"
                                id="mastercardUid"
                                name="mastercardUid"
                                label="Mastercard UID"
                                type="text"
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose}>Cancel</Button>
                            <Button classes={{ root: this.props.classes.trackButton }} type="submit">Recreate</Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>;
    }
}

const styles = {
    trackButton: {
        color: "#00a0de"
    },
    inputUnderline: {
        "&:after": {
            borderBottomColor: "#00a0de"
        },
        "&:error:after": {
            borderBottomColor: red[500]
        },
    },
    formLabelRoot: {
        "&$formLabelFocused": { color: "#00a0de" },
    },
    formLabelFocused: {
    },
    erroredLabel: {
        "&$formLabelFocused": { color: red[500] },
    }
};

export default withStyles(styles)(RecreateMobileKeyUserComponent);
