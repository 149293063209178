import * as React from "react";
import Notifier, { NotifierVariant } from "../../tools/Notifier";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import { List } from "immutable";
import { networkActions, QuerySort } from "../../actions/network";
import RootState from "../../states/RootState";
import { connect } from "react-redux";
import { BaseComponent } from "../BaseComponent";
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import FirmwareInfo from "../../models/FirmwareInfo";
import { getState } from "../../reducers/FirmwaresReducer";
import FirmwaresTableSearch from "./FirmwaresTableSearch";
import moment = require("moment");
import PTagInfo from "models/PTagInfo";

const dateFormat = (date: Date) => moment(date).format("DD.MM.YYYY HH:mm:ss");

interface FirmwaresListProps {
    readonly env: string;
    readonly progress: boolean;
    readonly firmwares: List<FirmwareInfo>;
    readonly offset: number;
    readonly limit: number;
    readonly count: number;
    readonly pTags: List<PTagInfo>;
    readonly errorMessage: string | null;
}

interface FirmwaresListState {
    readonly sortColumnIndex: number;
    readonly sortDirection?: "asc" | "desc" | "none";
}

const NULL = "NULL";

const renderNullableTextCell = (value: string, tableMeta: any, updateValue: any): string | React.ReactNode => {
    if (value === NULL) {
        return <span className="tableNullCell">{value}</span>;
    } else {
        return value;
    }
};

type FirmwareTableColumn = MUIDataTableColumn & {
    dataGetter(firmware: FirmwareInfo): any;
    readonly sortIds: ReadonlyArray<string>;
};

type PTagTableColumn = MUIDataTableColumn & {
    dataGetter(ptag: PTagInfo): any;
    readonly sortIds: ReadonlyArray<string>;
};

const pTagColumns: PTagTableColumn[] = [
    {
        name: "Device Type",
        sortIds: ["deviceType"],
        dataGetter: (ptag: PTagInfo) => ptag.deviceType,
        options: {
            filter: false,
        }
    },
    {
        name: "Count",
        sortIds: ["devicesCount"],
        dataGetter: (ptag: PTagInfo) => ptag.devicesCount,
        options: {
            filter: false,
        }
    },
]
/* tslint:disable:readonly-array */
const tableColumns: FirmwareTableColumn[] = [
    {
        name: "Device Type",
        sortIds: ["id"],
        dataGetter: (firmware: FirmwareInfo) => firmware.deviceType,
        options: {
            filter: false,
        }
    },
    {
        name: "Version",
        sortIds: ["revision"],
        dataGetter: (firmware: FirmwareInfo) => firmware.version,
        options: {
            filter: false,
        }
    },
    {
        name: "Date",
        sortIds: ["datetime"],
        dataGetter: (firmware: FirmwareInfo) => firmware.uploadedAt ? dateFormat(firmware.uploadedAt) : NULL,
        options: {
            filter: false,
            customBodyRender: renderNullableTextCell,
        }
    },
    {
        name: "Devices",
        sortIds: ["devicesCount"],
        dataGetter: (firmware: FirmwareInfo) => firmware.devicesCount,
        options: {
            filter: false,
        }
    },
];

export class FirmwaresListComponent extends BaseComponent<FirmwaresListProps, FirmwaresListState> {

    public readonly state: FirmwaresListState = {
        sortColumnIndex: -1
    };

    // tslint:disable-next-line:max-params
    private loadFirmwares(offset: number, limit: number, sorts: ReadonlyArray<QuerySort>, search?: string | null): void {
        this.props.dispatch(networkActions.getFirmwaresList(this.props.env, offset, limit, sorts, search));
    }

    private loadPtags(search?: string) {
        this.props.dispatch(networkActions.getPtagsStats(this.props.env, search))
    }


    public componentWillMount(): void {
        if (this.props.env) {
            this.props.dispatch(networkActions.getFirmwaresStats(this.props.env));
            this.loadFirmwares(this.props.offset, this.props.limit, []);
            this.loadPtags()
        }
    }

    public componentDidUpdate(prevProps: Readonly<FirmwaresListProps>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.env !== this.props.env) {
            this.props.dispatch(networkActions.getFirmwaresStats(this.props.env));
            this.loadFirmwares(this.props.offset, this.props.limit, []);
            this.loadPtags()
        }
    }

    public render(): any {
        if (this.state.sortColumnIndex >= 0) {
            tableColumns[this.state.sortColumnIndex].options!.sortDirection = this.state.sortDirection;
        }
        const pTagOptions: MUIDataTableOptions = {
            selectableRows: "none",
            filter: false,
            print: false,
            sort: true,
            pagination: false,
            onTableChange: (action: any, tableState: MUIDataTableState) => {
                if (action === "search" || action === "changeRowsPerPage") {
                    this.loadPtags()
                }
            },
        }
        const options: MUIDataTableOptions = {
            filterType: "dropdown",
            responsive: "scrollFullHeight",
            selectableRows: "none",
            pagination: false,
            sort: true,
            filter: false,
            print: false,
            rowsPerPage: this.props.limit,
            rowsPerPageOptions: [25, 50, 100, 150, 200, 250, 300, 400, 500, 1000],
            count: this.props.count,
            serverSide: true,
            downloadOptions: {
                filename: "report.csv"
            },
            onTableChange: (action: any, tableState: MUIDataTableState) => {
                if (action === "sort" || action === "changePage" || action === "search" || action === "changeRowsPerPage") {
                    // @ts-ignore
                    const column = tableColumns[tableState.activeColumn];
                    const columnState = tableState.columns.find((item: any) => item.sortDirection !== "none");
                    const sorts = columnState ? column.sortIds.map((id: string) => new QuerySort(id, columnState.sortDirection!)) : [];
                    this.loadFirmwares(tableState.page * tableState.rowsPerPage, tableState.rowsPerPage, sorts, tableState.searchText);
                    if (columnState) {
                        this.setState({
                            // @ts-ignore
                            sortColumnIndex: tableState.activeColumn as number,
                            sortDirection: columnState.sortDirection,
                        });
                    }
                }
            },
            searchPlaceholder: "Type your search and press Enter",
            // tslint:disable-next-line:max-params no-shadowed-variable
            customSearchRender: (searchText, handleSearch, hideSearch, options) => {
                return <FirmwaresTableSearch
                    searchText={searchText}
                    onSearch={handleSearch}
                    onHide={hideSearch}
                    options={options}
                />;
            },
        };

        const error = this.props.errorMessage
            ? <Notifier
                message={this.props.errorMessage}
                open={true}
                variant={NotifierVariant.ERROR}
            />
            : null;
        const tableData: any = this.props.firmwares.map((value: FirmwareInfo): ReadonlyArray<any> => {
            return tableColumns.map((column: any) => column.dataGetter(value));
        });
        const pTags: any = this.props.pTags.map((value: PTagInfo): ReadonlyArray<any> => {
            return pTagColumns.map((column: any) => column.dataGetter(value));
        });
        console.log("P Tags = " + JSON.parse(JSON.stringify(pTags)))
        return <main className="firmwares">
            <MUIDataTable
                title="Provisioning Files"
                columns={pTagColumns}
                data={pTags}
                options={pTagOptions}
            />

            <div className="progressContainer">
                {this.props.progress && <LinearProgress />}
            </div>
            <MUIDataTable
                title="Firmware files"
                columns={tableColumns}
                data={tableData}
                options={options}
            />
            {error}
        </main>;
    }
}

export default connect((state: RootState, ownProps: FirmwaresListProps): FirmwaresListProps => {
    const firmwaresState = getState(state);
    return {
        env: ownProps.env,
        progress: firmwaresState.progress,
        firmwares: firmwaresState.firmwares,
        offset: firmwaresState.offset,
        limit: firmwaresState.limit,
        count: firmwaresState.count,
        pTags: firmwaresState.ptags,
        errorMessage: firmwaresState.error ? firmwaresState.error.message : null
    };
})(FirmwaresListComponent);
