import {createActions} from "redux-actions";
import Environment from "../models/Environment";
import {List} from "immutable";

export const SET_TITLE = "SET_TITLE";
export const SET_ENVS = "SET_ENVS";
export const SELECT_ENV = "SELECT_ENV";

export const appBarActions = createActions({
    [SET_TITLE]: (value: string) => value,
    [SET_ENVS]: (value: List<Environment>) => value,
    [SELECT_ENV]: (value: Environment) => value
});
