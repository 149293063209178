import Snackbar, {SnackbarOrigin} from "@material-ui/core/Snackbar/Snackbar";
import * as React from "react";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { orange, red, green } from "@material-ui/core/colors";
import { Slide, SlideProps } from "@material-ui/core";

export const enum NotifierVariant {
    NORMAL,
    ERROR,
    WARNING,
    SUCCESS
}

interface NotifierParams {
    readonly style?: React.CSSProperties;
    readonly anchorOrigin?: SnackbarOrigin;
}

const variantMap = new Map<NotifierVariant, NotifierParams>();
variantMap.set(NotifierVariant.NORMAL, {
    style: {},
});
variantMap.set(NotifierVariant.SUCCESS, {
    style: {backgroundColor: green[700]},
});
variantMap.set(NotifierVariant.ERROR, {
    style: { backgroundColor: red[700] },
});
variantMap.set(NotifierVariant.WARNING, {
    style: { backgroundColor: orange[700] },
});

type TransitionProps = Omit<SlideProps, "direction">;

// tslint:disable-next-line:typedef
function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />;
}

interface NotifierProps {

    readonly message: string;
    readonly open: boolean | true;
    readonly variant: NotifierVariant;

    onClose?(): void;
}

interface NotifierState {
    readonly open: boolean;
}

export default class Notifier extends React.Component<NotifierProps, NotifierState> {

    public componentWillMount(): void {
        this.setState({ open: this.props.open });
    }

    public render(): any {
        const variant = variantMap.get(this.props.variant) as NotifierParams;
        const message = <span id="snackbar-message-id">{this.props.message}</span>;
        const handleSnackbarClose = () => {
            this.setState({
                open: false
            });
            if (this.props.onClose) {
                this.props.onClose();
            }
        };

        return (
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                open={this.state.open}
                TransitionComponent={TransitionUp}
            >
                <SnackbarContent
                    className="snackbar-error"
                    aria-describedby="snackbar-message-id"
                    message={message}
                    style={variant.style}
                />
            </Snackbar>
        );
    }
}
