import UserSession from "../models/UserSession";
import {Store} from "redux";
import RootState from "../states/RootState";

interface PersistentState {
    readonly session: UserSession | null;
}

const persistedStateKey = "app_state";

let oldSavedState: PersistentState = {
    session: null,
};

export const persistenceMiddleware = (store: Store<any>) => {
    return (next: any) => (action: any) => {
        const returnValue = next(action);

        saveState(store.getState());

        return returnValue;
    };
};

const saveState = (state: RootState): void => {
    if (!state) {
        throw new Error("state can't be null");
    }

    const persistedState: PersistentState = {
        session: state.session,
    };

    if (oldSavedState.session !== persistedState.session) {
        const serializedState = JSON.stringify(persistedState);

        if (window.localStorage) {
            localStorage.setItem(persistedStateKey, serializedState);
            oldSavedState = persistedState;
        }
    }
};

export const restoreState = (defaultState: RootState): RootState => {

    if (!defaultState) {
        throw new Error("defaultState can't be null");
    }

    let restoredState: any = defaultState;

    if (window.localStorage) {
        const serializedState = localStorage.getItem(persistedStateKey);

        if (serializedState) {
            const rawState: PersistentState = JSON.parse(serializedState);

            if (rawState.session) {
                const userSession = rawState.session;

                oldSavedState = {...oldSavedState, session: userSession};
                restoredState = {...restoredState, session: userSession};
            }
        }
    }

    return restoredState;
};
