import * as React from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {networkActions} from "../actions/network";
import Button from "@material-ui/core/Button/Button";
import Notifier, {NotifierVariant} from "../tools/Notifier";
import {clearError} from "../actions";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import RootState from "../states/RootState";

interface LoginState {
    readonly progress: boolean | false;
}

interface LoginProps {
    readonly errorMessage: string | null;

    getUser(): void;

    clearError(): void;
}

export class LoginComponent extends React.Component<LoginProps, LoginState> {

    public componentWillMount(): void {
        this.setState({
            progress: false
        });
    }

    public componentDidUpdate(prevProps: Readonly<LoginProps>, prevState: Readonly<LoginState>, snapshot?: any): void {
        if (this.props.errorMessage && this.state.progress) {
            this.setState({
                progress: false
            });
        }
    }

    public render(): any {
        const onLoginClick = () => {
            const url = `${process.env.API_ENDPOINT}/auth/google/login?from=${encodeURIComponent(
                location.origin + location.pathname
            )}?selfClose`;
            const newWindow = window.open(url);
            let secondsPass = 0;
            const checkMsDelay = 300;
            const checkInterval = setInterval(() => {
                let shouldProceed;
                secondsPass += checkMsDelay;
                try {
                    shouldProceed = (newWindow && newWindow.closed) || secondsPass > 30000;
                } catch (e) {
                    // empty
                }
                if (shouldProceed) {
                    clearInterval(checkInterval);
                    this.props.getUser();
                }
            }, checkMsDelay);
        };

        const error = this.props.errorMessage
            ? <Notifier
                message="Access denied"
                open={true}
                onClose={this.props.clearError}
                variant={NotifierVariant.ERROR}
            />
            : [];

        const progressComponent = this.state.progress ? <LinearProgress/> : [];
        return (
            <div className="login">
                {progressComponent}
                <div className="login-content">
                    <div className="login-plate">
                        <p>Authenticate using DOM Group App</p>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onLoginClick}
                            role="link"
                            disabled={this.state.progress}
                        >
                            Login with Google
                        </Button>
                        {error}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state: RootState) => {
        return {
            errorMessage: state.error !== null ? state.error.message : null,
            createSession: null
        };
    }, (dispatch: Dispatch) => ({
        getUser: () => {
            dispatch(networkActions.getUser());
        },
        clearError: () => {
            dispatch(clearError());
        }
    })
)(LoginComponent);
