import * as React from "react";
import {Grow, IconButton, TextField} from "@material-ui/core";
import {Clear, Search} from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";

interface SimpleTableSearchProps {

    readonly searchText: string;
    readonly options: any;

    onSearch(search: string): void;

    onHide(): void;
}

interface SimpleTableSearchState {

    readonly error: boolean;
    readonly helperText: string;
}

const SearchTextField = withStyles({
    root: {
        "& .MuiInput-underline:after": {
            borderBottomColor: "#00A0DE",
        },
    }
})(TextField);

export default class FirmwaresTableSearch extends React.Component<SimpleTableSearchProps, SimpleTableSearchState> {

    public readonly state: SimpleTableSearchState = {error: false, helperText: ""};

    public componentDidMount(): void {
        document.addEventListener("keydown", this.onKeyDown, false);
    }

    public componentWillUnmount(): void {
        document.removeEventListener("keydown", this.onKeyDown, false);
    }

    private readonly onKeyDown = (event: any) => {
        if (event.keyCode === 27) {
            this.props.onHide();
        }
        if (event.keyCode === 13) {
            const searchText = event.target.value;
            if (searchText.length >= 8) {
                this.props.onSearch(event.target.value);
            } else {
                this.setState({
                    error: true,
                    helperText: "Type a serial number and press enter",
                });
            }
        }
    }

    public render(): any {
        const handleOnChange = () => {
            this.setState({
                error: false,
                helperText: ""
            });
        };
        return <Grow appear in={true} timeout={300}>
            <div className="search">
                <Search className="search-icon"/>
                <SearchTextField
                    error={this.state.error}
                    helperText={this.state.helperText}
                    className="text"
                    placeholder={this.props.options.searchPlaceholder}
                    InputProps={{
                        "aria-label": this.props.options.textLabels.toolbar.search
                    }}
                    defaultValue={this.props.searchText || ""}
                    fullWidth={true}
                    autoFocus={true}
                    onChange={handleOnChange}
                />
                <IconButton onClick={this.props.onHide} className="clear-icon">
                    <Clear/>
                </IconButton>
            </div>
        </Grow>;
    }
}
