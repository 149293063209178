import {combineReducers} from "redux";
import {SessionReducer} from "./SessionReducer";
import {ComponentsReducers} from "./ComponentsReducers";
import {ErrorReducer} from "./ErrorReducer";
import {AppBarReducer} from "./AppBarReducer";
import RootState from "../states/RootState";
import {DefaultAppBarState} from "../states/AppBarState";

export const initialState: RootState = {
    session: null,
    appBar: DefaultAppBarState,
    components: {},
    error: null
};
export const reducers = combineReducers<RootState>({
    session: SessionReducer,
    appBar: AppBarReducer,
    components: ComponentsReducers,
    error: ErrorReducer
});
