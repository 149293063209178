import {createMuiTheme} from "@material-ui/core";
import {red} from "@material-ui/core/colors";

export const appTheme = createMuiTheme({
    typography: {
        fontSize: 12,
    },
    palette: {
        primary: {
            contrastText: "white",
            dark: red[900],
            main: red[500],
            light: red[100]
        },
        secondary: {
            contrastText: "white",
            dark: red[900],
            main: red[500],
            light: red[100]
        },
    },
    overrides: {
        MuiToolbar: {
            regular: {
                "minHeight": 52,
                "@media (min-width: 600px)": {
                    minHeight: 52
                }
            },
        },
        MuiTableRow: {
            head: {
                height: 40
            },
            root: {
                height: 42
            }
        },
        MuiTableCell:{
            root:{
                padding: 10
            }
        }
    },
});
