import { handleActions } from "redux-actions";
import RootState from "../states/RootState";
import { reduceAxiosFail } from "../states/ErrorState";
import { List } from "immutable";
import FirmwaresState from "../states/FirmwaresState";

const defaultState: FirmwaresState = {
    firmwares: List(),
    count: 0,
    offset: 0,
    limit: 25,
    progress: false,
    ptags: List(),
    error: null
};

export const FirmwaresReducer = handleActions<FirmwaresState>({
    GET_FIRMWARES_LIST: (state: FirmwaresState, action: any): FirmwaresState => ({
        firmwares: state.firmwares,
        count: state.count,
        offset: action.payload.request.params.offset,
        limit: action.payload.request.params.limit,
        progress: true,
        ptags: state.ptags,
        error: null
    }),
    GET_FIRMWARES_LIST_SUCCESS: (state, action: any): FirmwaresState => ({
        firmwares: action.payload.data,
        count: state.count,
        offset: state.offset,
        limit: state.limit,
        progress: false,
        ptags: state.ptags,
        error: null
    }),
    GET_FIRMWARES_LIST_FAIL: (state, action: any): FirmwaresState => ({
        firmwares: defaultState.firmwares,
        count: defaultState.count,
        offset: defaultState.offset,
        limit: defaultState.limit,
        progress: defaultState.progress,
        ptags: state.ptags,
        error: reduceAxiosFail(state, action)
    }),
    GET_FIRMWARES_STATS: (state: FirmwaresState, action: any): FirmwaresState => ({
        firmwares: state.firmwares,
        count: 0,
        offset: defaultState.offset,
        limit: defaultState.limit,
        progress: true,
        ptags: state.ptags,
        error: null
    }),
    GET_FIRMWARES_STATS_SUCCESS: (state: FirmwaresState, action: any): FirmwaresState => ({
        firmwares: state.firmwares,
        count: action.payload.data.count,
        offset: state.offset,
        limit: state.limit,
        progress: state.progress,
        ptags: state.ptags,
        error: null
    }),
    GET_PTAGS_STATS: (state: FirmwaresState, action: any): FirmwaresState => ({
        firmwares: state.firmwares,
        count: state.count,
        offset: state.offset,
        limit: state.limit,
        progress: true,
        ptags: state.ptags,
        error: null
    }),
    GET_PTAGS_STATS_FAIL: (state, action: any): FirmwaresState => ({
        firmwares: state.firmwares,
        count: state.count,
        offset: state.offset,
        limit: state.limit,
        progress: state.progress,
        ptags: state.ptags,
        error: reduceAxiosFail(state, action)
    }),
    GET_PTAGS_STATS_SUCCESS: (state: FirmwaresState, action: any): FirmwaresState => ({
        firmwares: state.firmwares,
        count: state.count,
        offset: state.offset,
        limit: state.limit,
        progress: state.progress,
        ptags: action.payload.data,
        error: null
    }),
}, defaultState);

export const getState = (state: RootState): FirmwaresState => state.components!.firmwares;
