import FirmwareCloudUpdateJournalState from "../states/FirmwaresCloudUpdateJournalState";
import { List } from "immutable";
import RootState from "states/RootState";
import { handleActions } from "redux-actions";
import { reduceAxiosFail } from "states/ErrorState";

const defaultState: FirmwareCloudUpdateJournalState = {
    firmwares: List(),
    count: 0,
    offset: 0,
    limit: 25,
    progress: false,
    error: null
};

export const FirmwareCloudUpdateReducer = handleActions<FirmwareCloudUpdateJournalState>({
    GET_FIRMWARE_UPDATE_CLOUD_LIST: (state: FirmwareCloudUpdateJournalState, action: any): FirmwareCloudUpdateJournalState => ({
        firmwares: state.firmwares,
        count: state.count,
        offset: action.payload.request.params.offset,
        limit: action.payload.request.params.limit,
        progress: true,
        error: null
    }),
    GET_FIRMWARE_UPDATE_CLOUD_LIST_SUCCESS: (state, action: any): FirmwareCloudUpdateJournalState => ({
        firmwares: action.payload.data,
        count: state.count,
        offset: state.offset,
        limit: state.limit,
        progress: false,
        error: null
    }),
    GET_FIRMWARE_UPDATE_CLOUD_LIST_FAIL: (state, action: any): FirmwareCloudUpdateJournalState => ({
        firmwares: defaultState.firmwares,
        count: defaultState.count,
        offset: defaultState.offset,
        limit: defaultState.limit,
        progress: defaultState.progress,
        error: reduceAxiosFail(state, action)
    }),
    GET_FIRMWARE_UPDATE_CLOUD_STATS: (state: FirmwareCloudUpdateJournalState, action: any): FirmwareCloudUpdateJournalState => ({
        firmwares: state.firmwares,
        count: 0,
        offset: defaultState.offset,
        limit: defaultState.limit,
        progress: true,
        error: null
    }),
    GET_FIRMWARE_UPDATE_CLOUD_STATS_SUCCESS: (state: FirmwareCloudUpdateJournalState, action: any): FirmwareCloudUpdateJournalState => ({
        firmwares: state.firmwares,
        count: action.payload.data.count,
        offset: state.offset,
        limit: state.limit,
        progress: state.progress,
        error: null
    }),
}, defaultState);

export const getState = (state: RootState): FirmwareCloudUpdateJournalState => state.components!.firmwareJournal;
