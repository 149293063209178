import {connect} from "react-redux";
import * as React from "react";
import AppBar from "@material-ui/core/AppBar";
import RootState from "../states/RootState";
import {Avatar, IconButton, InputAdornment, Menu, MenuItem, Select, Toolbar, Typography} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import {Dispatch} from "redux";
import {networkActions} from "../actions/network";
import {CloudCircle} from "@material-ui/icons";
import {List} from "immutable";
import Environment from "../models/Environment";
import {appBarActions} from "../actions/appbar";

interface AppBarState {
    readonly menuAnchor: any | null;
}

interface AppBarProps {
    readonly title: string;
    readonly avatar: string;
    readonly environments: List<Environment>;
    readonly selectedEnvironment: Environment | null;

    onMenuClick(): void;

    logout(): void;

    changeEnv(environment: Environment): void;
}

export class AppBarComponent extends React.Component<AppBarProps, AppBarState> {

    public readonly state: AppBarState = {
        menuAnchor: null,
    };

    public render(): any {
        const handleMenu = (event: any) => {
            this.setState({menuAnchor: event.currentTarget});
        };
        const handleClose = () => {
            this.setState({menuAnchor: null});
        };
        const handleLogout = () => {
            this.props.logout();
        };
        const handleEnvChange = (event: any) => {
            this.props.changeEnv(this.props.environments.find((value) => value.id === event.target.value)!);
        };
        const selectEnv = this.props.environments.size && this.props.selectedEnvironment ?
            <Select
                className="select"
                value={this.props.selectedEnvironment.id}
                displayEmpty
                classes={{
                    icon: "arrow"
                }}
                disableUnderline={true}
                onChange={handleEnvChange}
                startAdornment={<InputAdornment position="start"><CloudCircle className={"icon"}/></InputAdornment>}
            >
                {
                    this.props.environments.map((value) => {
                        return <MenuItem value={value.id} color="inherit">{value.title}</MenuItem>;
                    })
                }
            </Select>
            : [];
        return <AppBar position="sticky" className="appBar" color="primary">
            <Toolbar color="inherit">
                <IconButton color="inherit" aria-label="Menu" className="menuButton" onClick={this.props.onMenuClick}>
                    <MenuIcon/>
                </IconButton>
                <Typography variant="h5" color="inherit" className="title">
                    {this.props.title}
                </Typography>
                {selectEnv}
                <div className={"grow"}/>
                <IconButton
                    aria-haspopup="true"
                    aria-owns={this.state.menuAnchor ? "profile-menu" : undefined}
                    color="inherit"
                    onClick={handleMenu}
                >
                    <Avatar alt="Remy Sharp" src={this.props.avatar} className="avatar"/>
                </IconButton>
                <Menu
                    id="profile-menu"
                    anchorEl={this.state.menuAnchor}
                    open={Boolean(this.state.menuAnchor)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>;
    }
}

export default connect((state: RootState) => {
        return {
            avatar: state.session?.pic,
            title: state.appBar.title,
            environments: state.appBar.environments,
            selectedEnvironment: state.appBar.selectedEnvironment
        };
    }, (dispatch: Dispatch) => ({
        logout: () => {
            dispatch(networkActions.logout());
        },
        changeEnv: (environment: Environment) => {
            dispatch(appBarActions.selectEnv(environment));
        }
    })
)(AppBarComponent);
