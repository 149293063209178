import { BaseComponent } from "components/BaseComponent";
import Environment from "models/Environment";
import { connect } from "react-redux";
import RootState from "states/RootState";
import { appBarActions } from "actions/appbar";
import { networkActions } from "actions/network";
import { List } from "immutable";
import * as React from "react";
import { CircularProgress, Tabs, Tab } from "@material-ui/core";
import DashboardComponent from "components/common/DashboardComponent";
import { AUTH_INFO_DASHBOARD } from "states/DashboardState";
import AuthAuditComponent from "./AuthAuditComponent";

interface AuthProps {
    readonly env: Environment;
}

interface AuthState {
    readonly tabIndex: number;
}

const envs = List([
    {
        id: "dev",
        title: "Development"
    },
    {
        id: "prod",
        title: "Production"
    }
]);

const tabs: ReadonlyArray<any> = [
    {
        path: "dashboard",
        label: "Dashboard",
        component: DashboardComponent
    },
    {
        path: "audit",
        label: "Audit",
        component: AuthAuditComponent
    },
];

export class AuthInfoComponent extends BaseComponent<AuthProps, AuthState> {

    public readonly state: AuthState = {
        tabIndex: 0,
    };

    public componentWillMount(): void {
        let env = this.props.match.params.env;
        let tab = this.props.match.params.tab;
        if (!env) {
            env = envs.first({ id: "dev" }).id;
        }
        if (!tab) {
            tab = tabs[0].path;
        }
        const location = `/auth_info/${env}/${tab}`;
        if (this.props.history.location.pathname !== location) {
            this.props.history.replace(location);
        }
        this.props.dispatch(appBarActions.setTitle("Auth Info"));
        this.props.dispatch(appBarActions.setEnvs(envs));
        this.props.dispatch(appBarActions.selectEnv(envs.find((value) => value.id === env)));
        this.props.dispatch(networkActions.getAuthInfo(env));
    }

    public componentDidUpdate(prevProps: Readonly<AuthProps>, prevState: Readonly<any>, snapshot?: any): void {
        const env = this.props.match.params.env;
        const tab = this.props.match.params.tab;
        if (env && this.props.env.id !== env) {
            this.props.history.replace(`/auth_info/${this.props.env.id}/${tab}`);
            this.props.dispatch(networkActions.getAuthInfo(env));
        }
    }

    public render(): any {
        const handleTabChange = (event: any, value: any) => {
            this.props.history.replace(tabs[value].path);
        };
        let tabIndex = tabs.findIndex((value) => value.path === this.props.match.params.tab);
        if (tabIndex < 0) {
            tabIndex = 0;
        }
        return <main className={"content server"}>
            <Tabs
                value={tabIndex}
                indicatorColor="secondary"
                textColor="secondary"
                onChange={handleTabChange}
            >
                {
                    tabs.map((value) => {
                        return <Tab value={value.value} label={value.label} className={"tab"} />;
                    })
                }
            </Tabs>
            <div className={"content"}>
                {this.props.env ? React.createElement(tabs[tabIndex].component, {
                    type: AUTH_INFO_DASHBOARD,
                    errorsProject: `auth-${this.props.env!.id}`,
                    env: this.props.env!.id,
                }) : <CircularProgress />}
            </div>
        </main>;
    }
}

export default connect((state: RootState): AuthProps => {
    return {
        env: state.appBar.selectedEnvironment!
    };
})(AuthInfoComponent);
