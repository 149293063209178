import * as React from "react";
import {List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";

interface MenuProps {
    readonly navigations: ReadonlyArray<NavigationItem>;
    readonly selected: number;

    onNavigate(location: string): void;
}

export interface NavigationItem {
    readonly icon: any;
    readonly text: string;
    readonly location: string;
    readonly selected: boolean;
}

export default class MenuComponent extends React.PureComponent<MenuProps, any> {

    public render(): any {
        return <List className="menu" component="nav">
            {
                this.props.navigations.map((item: NavigationItem, index: number) => {
                        const handleClick = (event: any) => {
                            this.props.onNavigate(item.location);
                        };
                        return <ListItem button selected={index === this.props.selected} onClick={handleClick}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text}/>
                        </ListItem>;
                    }
                )
            }
        </List>;
    }
}
