import {SvgIcon} from "@material-ui/core";
import * as React from "react";

export default class LicensingIconComponent extends React.Component<{}, {}> {
    public render(): any {
        return <SvgIcon viewBox="0 0 20 20" {...this.props}>
            <path fill="#000" d="M10.833 4.167v5h.975L10 10.975 8.192 9.167h.975v-5h1.666M12.5 2.5h-5v5H4.167L10 13.333 15.833 7.5H12.5v-5zM15.833 15H4.167v1.667h11.666V15z"/>
        </SvgIcon>;
    }
}
