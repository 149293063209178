import * as React from "react";
import { BaseComponent } from "../BaseComponent";
import { Paper, Table, TableBody, TableCell, TableRow, Toolbar, Typography } from "@material-ui/core";
import ServerInfo from "../../models/ServerInfo";
import { connect } from "react-redux";
import RootState from "../../states/RootState";
import { BugReport, InfoRounded } from "@material-ui/icons";
import { List } from "immutable";
import ErrorItem from "../../models/ErrorItem";
import { networkActions } from "../../actions/network";
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import Notifier, { NotifierVariant } from "../../tools/Notifier";
import moment = require("moment");

const dateFormat = (date: Date) => moment(date).format("DD.MM.YYYY HH:mm:ss");

interface DashboardProps {
    readonly env: string;
    readonly type: string;
    readonly progress: boolean;
    readonly errors: List<ErrorItem>;
    readonly errorsProject: string;
    readonly serverInfo: ServerInfo | null;
    readonly errorMessage: string | null;
}

type ErrorTableColumn = MUIDataTableColumn & {
    dataGetter(item: any): any;
};

/* tslint:disable:readonly-array */
const tableColumns: ErrorTableColumn[] = [
    {
        name: "ShortId",
        dataGetter: (item: ErrorItem) => item.shortId,
    },
    {
        name: "Date",
        dataGetter: (item: ErrorItem) => dateFormat(new Date(item.lastSeen)),
    },
    {
        name: "Error",
        dataGetter: (item: ErrorItem) => item.title,
    },
    {
        name: "Level",
        dataGetter: (item: ErrorItem) => item.level,
    },
    {
        name: "Link",
        dataGetter: (item: ErrorItem) => item.permalink,
    },
    {
        name: "User Count",
        dataGetter: (item: ErrorItem) => item.userCount,
    },
    {
        name: "Count",
        dataGetter: (item: ErrorItem) => item.count,
    }
];

export class DashboardComponent extends BaseComponent<DashboardProps, any> {

    private loadErrors(): void {
        this.props.dispatch(networkActions.getErrors(this.props.type, this.props.env, "eniq-fwu"));
    }

    public componentWillMount(): void {
        this.loadErrors();
    }

    public componentDidUpdate(prevProps: Readonly<DashboardProps>, prevState: Readonly<DashboardProps>, snapshot?: any): void {
        if (prevProps.errorsProject !== this.props.errorsProject) {
            this.loadErrors();
        }
    }

    public render(): any {
        const serverInfoElement = this.props.serverInfo
            ? <Table className="info-content">
                <TableBody>
                    {[
                        this.renderInfoRow("Version", this.props.serverInfo.version),
                        this.renderInfoRow("Domain", this.props.serverInfo.domain),
                        this.renderInfoRow("IP", this.props.serverInfo.ip),
                    ]}
                </TableBody>
            </Table>
            : [];
        /* tslint:disable:array-type */
        const errorsData: any = this.props.errors ? this.props.errors.map((value) => {
            return tableColumns.map((column: any) => column.dataGetter(value));
        }) : [];
        const options: MUIDataTableOptions = {
            responsive: "scrollMaxHeight",
            selectableRows: "none",
            pagination: false,
            sort: false,
            filter: false,
            search: false,
            print: false,
            serverSide: true,
            downloadOptions: {
                filename: "report.csv"
            }
        };
        const error = this.props.errorMessage
            ? <Notifier
                message={this.props.errorMessage}
                open={true}
                variant={NotifierVariant.ERROR}
            />
            : null;
        return <main className="dashboard">
            <div className="errors">
                <MUIDataTable
                    title={<Toolbar color="inherit" className="title-content" style={{ paddingLeft: "0px" }}>
                        <BugReport className="icon" />
                        <Typography variant="subtitle1" className="title">Errors</Typography>
                    </Toolbar>}
                    columns={tableColumns}
                    data={errorsData}
                    options={options}
                />
            </div>
            <Paper className="info">
                <Toolbar
                    color="inherit"
                    className="title-content"
                >
                    <InfoRounded className="icon" />
                    <Typography variant="subtitle1" className="title">Information</Typography>
                </Toolbar>
                {serverInfoElement}
            </Paper>
            {error}
        </main>;
    }

    private renderInfoRow(label: string, value: any): any {
        return <TableRow>
            <TableCell scope="row">
                <Typography color="textSecondary">
                    {label}:
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography color="textSecondary">
                    {value}
                </Typography>
            </TableCell>
        </TableRow>;
    }
}

export default connect((state: RootState, ownProps: DashboardProps): DashboardProps => {
    const dashboardState = state.components.dashboards[ownProps.type];
    const serverInfo = dashboardState ? dashboardState.serverInfo : null;
    const errorMessage = dashboardState && dashboardState.error ? dashboardState.error.message : null;
    const errorsList = dashboardState ? dashboardState.errorsList : List();
    const progress = dashboardState ? dashboardState.progress : false;
    return {
        env: ownProps.env,  
        errors: errorsList,
        progress: progress,
        type: ownProps.type,
        errorsProject: ownProps.errorsProject,
        serverInfo: serverInfo,
        errorMessage: errorMessage
    };
})(DashboardComponent);
