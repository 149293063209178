import * as React from "react";
import Notifier, { NotifierVariant } from "../../tools/Notifier";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import { List } from "immutable";
import { networkActions } from "../../actions/network";
import RootState from "../../states/RootState";
import { connect } from "react-redux";
import { BaseComponent } from "../BaseComponent";
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import AuditItem from "models/AuditItem";
import moment = require("moment");
import { getState } from "reducers/AuthAuditReducer";

const dateFormat = (date: Date) => moment(date).utc().format("DD.MM.YYYY HH:mm:ss'Z");

interface AuditListProps {
    readonly env: string;
    readonly progress: boolean;
    readonly items: List<AuditItem>;
    readonly offset: number;
    readonly limit: number;
    readonly count: number;
    readonly errorMessage: string | null;
}

type AuditTableColumn = MUIDataTableColumn & {
    dataGetter(item: AuditItem): any;
};

/* tslint:disable:readonly-array */
const tableColumns: AuditTableColumn[] = [
    {
        name: "Id",
        dataGetter: (item: AuditItem) => item.id,
        options: {
            filter: false,
        }
    },
    {
        name: "Type",
        dataGetter: (item: AuditItem) => item.type,
        options: {
            filter: false,
        }
    },
    {
        name: "Consumer Id",
        dataGetter: (item: AuditItem) => item.consumerId,
        options: {
            filter: false,
        }
    },
    {
        name: "Date",
        dataGetter: (item: AuditItem) => dateFormat(item.dateTime),
        options: {
            filter: false,
        }
    },
    {
        name: "Info",
        dataGetter: (item: AuditItem) => item.info,
        options: {
            filter: false,
        }
    },
];

export class AuthAuditComponent extends BaseComponent<AuditListProps, any> {

    // tslint:disable-next-line:max-params
    private loadAuditItems(offset: number, limit: number): void {
        this.props.dispatch(networkActions.getAuditItems(this.props.env, offset, limit));
    }

    public componentWillMount(): void {
        if (this.props.env) {
            this.props.dispatch(networkActions.getAuditStats(this.props.env));
            this.loadAuditItems(this.props.offset, this.props.limit);
        }
    }

    public componentDidUpdate(prevProps: Readonly<AuditListProps>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.env !== this.props.env) {
            this.props.dispatch(networkActions.getAuditStats(this.props.env));
            this.loadAuditItems(this.props.offset, this.props.limit);
        }
    }

    public render(): any {
        const options: MUIDataTableOptions = {
            filterType: "dropdown",
            responsive: "scrollFullHeight",
            selectableRows: "none",
            pagination: this.props.limit < this.props.count,
            filter: false,
            print: false,
            sort: false,
            rowsPerPage: this.props.limit,
            rowsPerPageOptions: [25, 50, 100, 150, 200, 250, 300, 400, 500, 1000],
            count: this.props.count,
            serverSide: true,
            downloadOptions: {
                filename: "report.csv"
            },
            onTableChange: (action: any, tableState: MUIDataTableState) => {
                if (action === "changePage" || action === "changeRowsPerPage") {
                    this.loadAuditItems(tableState.page * tableState.rowsPerPage, tableState.rowsPerPage);
                }
            }
        };

        const error = this.props.errorMessage
            ? <Notifier
                message={this.props.errorMessage}
                open={true}
                variant={NotifierVariant.ERROR}
            />
            : null;
        const tableData: any = this.props.items.map((value: AuditItem): ReadonlyArray<any> => {
            return tableColumns.map((column: any) => column.dataGetter(value));
        }).toArray();
        console.log(tableData)
        return <main className="audit">
            <div className="progressContainer">
                {this.props.progress && <LinearProgress />}
            </div>
            <MUIDataTable
                title={null}
                columns={tableColumns}
                data={tableData}
                options={options}
            />
            {error}
        </main>;
    }
}

export default connect((state: RootState, ownProps: AuditListProps): AuditListProps => {
    const authState = getState(state);
    return {
        env: ownProps.env,
        progress: authState.progress,
        items: authState.items,
        offset: authState.offset,
        limit: authState.limit,
        count: authState.count,
        errorMessage: authState.error ? authState.error.message : null
    };
})(AuthAuditComponent);
