import { combineReducers } from "redux";
import { PurchasesReducer } from "./PurchasesReducer";
import ComponentsState from "../states/ComponentsState";
import { DashboardsReducer } from "./DashboardsReducer";
import { AuthAuditReducer } from "./AuthAuditReducer";
import { FirmwaresReducer } from "./FirmwaresReducer";
import { FirmwareCloudUpdateReducer } from "./FirmwareCloudUpdateJournalReducer";
import { ToolsReducer } from "./ToolsReducer";
import {SoftwareLicensesReducer} from "./SoftwareLicensesReducer";

export const ComponentsReducers = combineReducers<ComponentsState>({
    dashboards: DashboardsReducer,
    purchases: PurchasesReducer,
    authState: AuthAuditReducer,
    firmwareJournal: FirmwareCloudUpdateReducer,
    firmwares: FirmwaresReducer,
    tools: ToolsReducer,
    softwareLicenses: SoftwareLicensesReducer,
});
