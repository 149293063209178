import ToolsState from "../states/ToolsState";
import RootState from "states/RootState";
import { handleActions } from "redux-actions";
import { reduceAxiosFail } from "states/ErrorState";

const defaultState: ToolsState = {
    successMessage: "",
    error: null
};

export const ToolsReducer = handleActions<ToolsState>({
    RESTORE_MOBILE_KEY_DEFAULT_PASSWORD: (state: ToolsState, action: any): ToolsState => ({
        successMessage: defaultState.successMessage,
        error: null
    }),
    RESTORE_MOBILE_KEY_DEFAULT_PASSWORD_SUCCESS: (state, action: any): ToolsState => ({
        successMessage: "Password successfully restored to default",
        error: null
    }),
    RESTORE_MOBILE_KEY_DEFAULT_PASSWORD_FAIL: (state, action: any): ToolsState => ({
        successMessage: defaultState.successMessage,
        error: reduceAxiosFail(state, action)
    }),
    RECREATE_MOBILE_KEY_USER: (state: ToolsState, action: any): ToolsState => ({
        successMessage: defaultState.successMessage,
        error: null
    }),
    RECREATE_MOBILE_KEY_USER_SUCCESS: (state, action: any): ToolsState => ({
        successMessage: "MobileKey User is recreated!",
        error: null
    }),
    RECREATE_MOBILE_KEY_USER_FAIL: (state, action: any): ToolsState => ({
        successMessage: defaultState.successMessage,
        error: reduceAxiosFail(state, action)
    }),
}, defaultState);

export const getState = (state: RootState): ToolsState => state.components!.tools;
