import * as React from "react";
import { Dialog } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";

interface TrackPurchaseProps {
    readonly selectedPurchaseId?: number;
    readonly open: boolean;
    readonly classes?: any;

    onClose?(): void;
    onSubmit?(enteredToken: string, enteredPrice: number, enteredCurrency: string): void;
}

interface TrackPurchaseState {
    readonly enteredValue: string;
    readonly enteredPrice: number;
    readonly enteredCurrency: string;
    readonly valueEmpty: boolean;
    readonly priceEmpty: boolean;
    readonly currencyEmpty: boolean;
    readonly open: boolean;
}

class TrackPurchaseComponent extends React.Component<TrackPurchaseProps, TrackPurchaseState> {
    private readonly handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            valueEmpty: false,
            enteredValue: event.target.value
        });
    }

    private readonly handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            priceEmpty: false,
            enteredPrice: Number(event.target.value)
        });
    }

    private readonly handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            currencyEmpty: false,
            enteredCurrency: event.target.value
        });
    }

    private readonly handleClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    private readonly handleSubmit = () => {
        if (this.state.enteredValue == null ||
            this.state.enteredCurrency == null ||
            this.state.enteredPrice == null) {
            this.setState({
                valueEmpty: this.state.enteredValue == null,
                priceEmpty: this.state.enteredPrice == null,
                currencyEmpty: this.state.enteredCurrency == null
            })
        } else {
            if (this.props.onSubmit) {
                this.props.onSubmit(this.state.enteredValue, this.state.enteredPrice, this.state.enteredCurrency);
            }
        }
    }

    public componentWillMount(): void {
        this.setState({ open: this.props.open });
    }

    public render(): any {

        return <Dialog open={this.state.open} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Track Purchase</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To update information about purchase with ID = {this.props.selectedPurchaseId}, enter its google token
                </DialogContentText>
                <TextField
                    InputLabelProps={{
                        classes: {
                            root: this.props.classes.formLabelRoot,
                            focused: this.props.classes.formLabelFocused,
                            error: this.props.classes.erroredLabel
                        },
                    }}
                    InputProps={{
                        classes: {
                            underline: this.props.classes.inputUnderline,
                            error: this.props.classes.error
                        },
                    }}
                    error={this.state.valueEmpty == true}
                    helperText={this.state.valueEmpty == true ? "Cannot be empty" : ""}
                    onChange={this.handleTextChange}
                    autoFocus
                    margin="dense"
                    id="token"
                    label="Google token"
                    type="text"
                    value={this.state.enteredValue}
                    fullWidth
                />
                <TextField
                    InputLabelProps={{
                        classes: {
                            root: this.props.classes.formLabelRoot,
                            focused: this.props.classes.formLabelFocused,
                            error: this.props.classes.erroredLabel
                        },
                    }}
                    InputProps={{
                        classes: {
                            underline: this.props.classes.inputUnderline,
                            error: this.props.classes.error
                        },
                    }}
                    error={this.state.priceEmpty == true}
                    helperText={this.state.priceEmpty == true ? "Cannot be empty" : ""}
                    onChange={this.handlePriceChange}
                    margin="dense"
                    id="prie"
                    label="Price"
                    type="number"
                    value={this.state.enteredPrice}
                    fullWidth
                />
                <TextField
                    InputLabelProps={{
                        classes: {
                            root: this.props.classes.formLabelRoot,
                            focused: this.props.classes.formLabelFocused,
                            error: this.props.classes.erroredLabel
                        },
                    }}
                    InputProps={{
                        classes: {
                            underline: this.props.classes.inputUnderline,
                            error: this.props.classes.error
                        },
                    }}
                    error={this.state.currencyEmpty == true}
                    helperText={this.state.currencyEmpty == true ? "Cannot be empty" : ""}
                    onChange={this.handleCurrencyChange}
                    margin="dense"
                    id="currency"
                    label="Currency"
                    type="text"
                    value={this.state.enteredCurrency}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose}> Cancel </Button>
                <Button classes={{ root: this.props.classes.trackButton }} onClick={this.handleSubmit}> Track Purchase </Button>
            </DialogActions>
        </Dialog>;
    }
}

const styles = {
    trackButton: {
        color: "#00a0de",
    },
    inputUnderline: {
        "&:after": {
            borderBottomColor: "#00a0de"
        },
        "&:error:after": {
            borderBottomColor: red[500]
        },
    },
    formLabelRoot: {
        "&$formLabelFocused" : { color: "#00a0de" },
    },
    formLabelFocused: {
    },
    erroredLabel: {
        "&$formLabelFocused" : { color: red[500] },
    }
};

export default withStyles(styles)(TrackPurchaseComponent);
