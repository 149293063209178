import * as React from "react";
import { Dialog } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";

interface RestoreMobileKeyPasswordProps {
    readonly classes?: any;
    onClose?(): void;
    onRestore(masterCardNumber: string, masterCardUid: string): void;
}

interface RestoreMobileKeyPasswordState {
    readonly masterCardNumberWrong: boolean;
    readonly masterCardNumber: string;
    readonly masterCardUidWrong: boolean;
    readonly masterCardUid: string;
}

const masterCardNumberPattern = /^[0-9]+$/;
const masterCardUidPattern = /^[0-9A-F]+$/;

class RestoreDefaultMobileKeyPasswordComponent extends React.Component<RestoreMobileKeyPasswordProps, RestoreMobileKeyPasswordState> {

    public readonly state: RestoreMobileKeyPasswordState = {
        masterCardNumberWrong: false,
        masterCardNumber: "",
        masterCardUidWrong: false,
        masterCardUid: "",
    };

    private readonly handleMasterCardNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            masterCardNumber: event.target.value
        });
    }

    private readonly handleMasterCardUidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            masterCardUid: event.target.value
        });
    }

    private readonly handleClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    private readonly handleSubmit = () => {
        const masterCardNumberCorrect = masterCardNumberPattern.test(this.state.masterCardNumber);
        const masterCardUidCorrect = masterCardUidPattern.test(this.state.masterCardUid);
        this.setState({ masterCardNumberWrong: !masterCardNumberCorrect, masterCardUidWrong: !masterCardUidCorrect });
        if (masterCardNumberCorrect && masterCardUidCorrect) {
            this.props.onRestore(this.state.masterCardNumber, this.state.masterCardUid);
        }
    }

    public render(): any {

        return <Dialog open={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Restore Default Mobile Key Password</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To restore Mobile Key password to default, please specify Master card number and uid.
                    Password will be automatically cleared to default on Firebase.
                    After that in one hour, user will have access to Mobile Key feature in the ENiQ app.
                </DialogContentText>
                <TextField
                    InputLabelProps={{
                        classes: {
                            root: this.props.classes.formLabelRoot,
                            focused: this.props.classes.formLabelFocused,
                            error: this.props.classes.erroredLabel
                        },
                    }}
                    InputProps={{
                        classes: {
                            underline: this.props.classes.inputUnderline,
                            error: this.props.classes.error
                        },
                    }}
                    error={this.state.masterCardNumberWrong}
                    helperText={this.state.masterCardNumberWrong ? "Should have 14 symbols comply [0-9] pattern" : ""}
                    onChange={this.handleMasterCardNumberChange}
                    autoFocus
                    margin="dense"
                    id="mastercard_number"
                    label="Mastercard number"
                    type="text"
                    value={this.state.masterCardNumber}
                    fullWidth
                />
                <TextField
                    InputLabelProps={{
                        classes: {
                            root: this.props.classes.formLabelRoot,
                            focused: this.props.classes.formLabelFocused,
                            error: this.props.classes.erroredLabel
                        },
                    }}
                    InputProps={{
                        classes: {
                            underline: this.props.classes.inputUnderline,
                            error: this.props.classes.error
                        },
                    }}
                    error={this.state.masterCardUidWrong}
                    helperText={this.state.masterCardUidWrong ? "Should have 14 symbols comply [0-9A-F] pattern" : ""}
                    onChange={this.handleMasterCardUidChange}
                    autoFocus
                    margin="dense"
                    id="mastercard_uid"
                    label="Mastercard UID"
                    type="text"
                    value={this.state.masterCardUid}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose}> Cancel </Button>
                <Button classes={{ root: this.props.classes.resetButton }} onClick={this.handleSubmit}> Reset </Button>
            </DialogActions>
        </Dialog>;
    }
}

const styles = {
    resetButton: {
        color: "#00a0de"
    },
    inputUnderline: {
        "&:after": {
            borderBottomColor: "#00a0de"
        },
        "&:error:after": {
            borderBottomColor: red[500]
        },
    },
    formLabelRoot: {
        "&$formLabelFocused" : { color: "#00a0de" },
    },
    formLabelFocused: {
    },
    erroredLabel: {
        "&$formLabelFocused" : { color: red[500] },
    }
};

export default withStyles(styles)(RestoreDefaultMobileKeyPasswordComponent);
