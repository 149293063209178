import { handleActions } from "redux-actions";
import PurchasesState from "../states/PurchasesState";
import RootState from "../states/RootState";
import { reduceAxiosFail } from "../states/ErrorState";
import { List } from "immutable";

const defaultState: PurchasesState = {
    purchases: List(),
    count: 0,
    offset: 0,
    limit: 25,
    freePurchases: List(),
    freePurchasesCount: 0,
    freePurchasesLimit: 25,
    freePurchasesOffset: 0,
    progress: false,
    error: null,
    shouldUpdateList: false,
    notification: ""
};

export const PurchasesReducer = handleActions<PurchasesState>({
    GET_PURCHASES_LIST: (state: PurchasesState, action: any): PurchasesState => ({
        ...state,
        offset: action.payload.request.params.offset,
        limit: action.payload.request.params.limit,
        progress: true,
        error: null,
        shouldUpdateList: false,
    }),
    GET_PURCHASES_LIST_SUCCESS: (state, action: any): PurchasesState => {
        return {
            ...state,
            purchases: List.of(...action.payload.data),
            progress: false,
            error: null,
            shouldUpdateList: false,
        };
    },
    GET_PURCHASES_LIST_FAIL: (state, action: any): PurchasesState => ({
        ...state,
        purchases: defaultState.purchases,
        count: defaultState.count,
        offset: defaultState.offset,
        limit: defaultState.limit,
        progress: defaultState.progress,
        error: reduceAxiosFail(state, action),
    }),
    GET_FREE_PURCHASES_LIST: (state: PurchasesState, action: any): PurchasesState => ({
        ...state,
        freePurchasesLimit: action.payload.request.params.limit,
        freePurchasesOffset: action.payload.request.params.offset,
        progress: true,
        error: null,
        shouldUpdateList: false,
    }),
    GET_FREE_PURCHASES_LIST_SUCCESS: (state, action: any): PurchasesState => {
        return {
            ...state,
            freePurchases: List.of(...action.payload.data),
            progress: false,
            error: null,
            shouldUpdateList: false
        };
    },
    GET_FREE_PURCHASES_LIST_FAIL: (state, action: any): PurchasesState => ({
        ...state,
        progress: defaultState.progress,
        freePurchases: defaultState.freePurchases,
        freePurchasesCount: defaultState.freePurchasesCount,
        freePurchasesLimit: defaultState.freePurchasesLimit,
        freePurchasesOffset: defaultState.freePurchasesOffset,
        error: reduceAxiosFail(state, action),
        shouldUpdateList: false
    }),
    GET_PURCHASES_STATS: (state: PurchasesState, action: any): PurchasesState => ({
        ...state,
        count: 0,
        offset: defaultState.offset,
        limit: defaultState.limit,
        progress: true,
        error: null,
        shouldUpdateList: false
    }),
    GET_FREE_PURCHASES_STATS: (state: PurchasesState, action: any): PurchasesState => ({
        ...state,
        progress: true,
        freePurchasesCount: 0,
        freePurchasesLimit: defaultState.freePurchasesLimit,
        freePurchasesOffset: defaultState.freePurchasesOffset,
        error: null,
        shouldUpdateList: false
    }),
    GET_PURCHASES_STATS_SUCCESS: (state: PurchasesState, action: any): PurchasesState => ({
        ...state,
        count: action.payload.data.count,
        error: null,
        shouldUpdateList: false
    }),
    GET_FREE_PURCHASES_STATS_SUCCESS: (state: PurchasesState, action: any): PurchasesState => ({
        ...state,
        freePurchasesCount: action.payload.data.count,
        error: null,
        shouldUpdateList: false
    }),
    TRACK_PURCHASE: (state: PurchasesState, action: any): PurchasesState => ({
        ...state,
        progress: true,
        error: null,
        shouldUpdateList: false
    }),
    TRACK_PURCHASE_SUCCESS: (state: PurchasesState, action: any): PurchasesState => ({
        ...state,
        progress: false,
        error: null,
        shouldUpdateList: true

    }),
    TRACK_PURCHASE_FAIL: (state, action: any): PurchasesState => ({
        ...state,
        progress: false,
        error: reduceAxiosFail(state, action),
        shouldUpdateList: false
    }),
    REMOVE_FREE_PURCHASE: (state: PurchasesState, action: any): PurchasesState => ({
        ...state,
        progress: true,
        error: null,
        shouldUpdateList: false
    }),
    REMOVE_FREE_PURCHASE_SUCCESS: (state: PurchasesState, action: any): PurchasesState => ({
        ...state,
        progress: false,
        error: null,
        shouldUpdateList: true
    }),
    REMOVE_FREE_PURCHASE_FAIL: (state: PurchasesState, action: any): PurchasesState => ({
        ...state,
        progress: false,
        error: reduceAxiosFail(state, action),
        shouldUpdateList: false
    }),
    CREATE_FREE_PURCHASE: (state: PurchasesState, action: any): PurchasesState => ({
        ...state,
        progress: true,
        error: null,
        shouldUpdateList: false
    }),
    CREATE_FREE_PURCHASE_SUCCESS: (state: PurchasesState, action: any): PurchasesState => ({
        ...state,
        progress: false,
        error: null,
        shouldUpdateList: true
    }),
    CREATE_FREE_PURCHASE_FAIL: (state, action: any): PurchasesState => ({
        ...state,
        progress: false,
        error: reduceAxiosFail(state, action),
        shouldUpdateList: false
    }),
    CLEAR_PURCHASE_INFO: (state: PurchasesState, action: any): PurchasesState => ({
        ...state,
        progress: true,
        error: null,
        shouldUpdateList: false
    }),
    CLEAR_PURCHASE_INFO_SUCCESS: (state: PurchasesState, action: any): PurchasesState => ({
        ...state,
        progress: false,
        error: null,
        shouldUpdateList: true

    }),
    CLEAR_PURCHASE_INFO_FAIL: (state, action: any): PurchasesState => ({
        ...state,
        progress: false,
        error: reduceAxiosFail(state, action),
        shouldUpdateList: false
    }),
    ADD_MOBILE_KEY_USER: (state, action: any): PurchasesState => ({
        ...state,
        progress: true,
        error: null,
        shouldUpdateList: false
    }),
    ADD_MOBILE_KEY_USER_SUCCESS: (state, action: any): PurchasesState => ({
        ...state,
        progress: false,
        error: null,
        shouldUpdateList: false,
        notification: `MobileKey User (${action.meta.previousAction.payload.request.data.mastercard}) is successfully added!`
    }),
    ADD_MOBILE_KEY_USER_FAIL: (state, action: any): PurchasesState => ({
        ...state,
        progress: false,
        error: reduceAxiosFail(state, action),
        shouldUpdateList: false
    }),
    CLEAR_PURCHASES_NOTIFICATION: (state, action: any): PurchasesState => ({
        ...state,
        notification: ""
    }),
}, defaultState);

export const getState = (state: RootState): PurchasesState => state.components!.purchases;
