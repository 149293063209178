import * as React from "react";
import Notifier, { NotifierVariant } from "../../../tools/Notifier";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import { List } from "immutable";
import * as moment from "moment";
import RootState from "../../../states/RootState";
import { connect } from "react-redux";
import { BaseComponent } from "../../BaseComponent";
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import { networkActions, QuerySort } from "../../../actions/network";
import { getState } from "../../../reducers/SoftwareLicensesReducer";
import SoftwareLicense from "../../../models/SoftwareLicense";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import SignedSoftwareLicense from "../../../models/SignedSoftwareLicense";

const dateFormat = (date: Date) => moment(date).utc().format("DD.MM.YYYY");

interface SoftwareLicensesProps {
    readonly env: string;
    readonly progress: boolean;
    readonly licenses: List<SoftwareLicense>;
    readonly offset: number;
    readonly limit: number;
    readonly count: number;
    readonly errorMessage: string | null;
    readonly canModifyRecords: boolean;
    readonly shouldUpdateList: boolean;
    readonly signedLicense: SignedSoftwareLicense | null;
}

interface SoftwareLicensesState {
    readonly softwareLicenseSortColumnIndex: number;
    readonly softwareLicenseSortDirection?: "asc" | "desc" | "none";
    readonly selectedLicense?: SoftwareLicense;
    readonly menuAnchor: null;
    readonly menuGenerateLicense: boolean;
}

type SoftwareLicensesTableColumn = MUIDataTableColumn & {
    dataGetter(item: any): any;
    readonly sortIds: ReadonlyArray<string>;
};

export class SoftwareLicensesComponent extends BaseComponent<SoftwareLicensesProps, SoftwareLicensesState> {
    private readonly menuButtonRenderer = (value: SoftwareLicense, tableMeta: any, updateValue: any): string | React.ReactNode => {
        if (value === undefined) {
            return <span className="tableEmptyCell" />;
        } else {
            return <div className="button_container">
                <IconButton
                    size="small"
                    aria-label="more"
                    onClick={this.moreClick(value)}
                >
                    <MoreVert />
                </IconButton>
            </div>;
        }
    }

    private readonly tableColumns: ReadonlyArray<SoftwareLicensesTableColumn> = [
        {
            name: "Electronic System Number",
            sortIds: ["esid"],
            dataGetter: (license: SoftwareLicense) => license.esid,
            options: {
                filter: false,
            },
        },
        {
            name: "Features",
            sortIds: ["features"],
            dataGetter: (license: SoftwareLicense) => license.features,
            options: {
                sort: false,
                filter: false,
                customBodyRender: (value: any) => {
                    const formattedFeatures = value
                        .map((feature: any) => {
                            const start = feature.start 
                                ? dateFormat(new Date(feature.start)) 
                                : "NOT_SET";
                            const expiry = feature.expiry 
                                ? dateFormat(new Date(feature.expiry)) 
                                : "NOT_SET";
                            
                            return `${feature.id}, ${feature.param}, ${start} - ${expiry}`;
                        })
                        .join("<br />");

                    return <div dangerouslySetInnerHTML={{ __html: formattedFeatures }} />;
                },
            },
        },
        {
            name: "Initial Mastercard",
            sortIds: ["mastercard"],
            dataGetter: (license: SoftwareLicense) => license.masterCard,
            options: {
                filter: false,
            },
        },
        {
            name: "OwnerAccountId",
            sortIds: ["owner_account_id"],
            dataGetter: (license: SoftwareLicense) => license.ownerAccountId,
            options: {
                filter: false,
            },
        },
        {
            name: "Note",
            sortIds: ["note"],
            dataGetter: (license: SoftwareLicense) => license.note,
            options: {
                filter: false,
            },
        },
        {
            name: "Next Expiry",
            sortIds: ["next_expiry"],
            dataGetter: (license: SoftwareLicense) => license.nextExpiry ? license.nextExpiry : "",
            options: {
                filter: false,
                customBodyRender: (value: any) => {
                    return (value !== undefined && value > 0) ? dateFormat(new Date(value)) : "";
                },
            },
        },
        {
            name: "Is One Feature Expired?",
            sortIds: ["is_one_feature_expired"],
            dataGetter: (license: SoftwareLicense) => license.isOneFeatureExpired,
            options: {
                filter: false,
                customBodyRender: (value: any) => {
                    return value ? "Yes" : "No";
                },
            },
        },
        {
            name: "",
            sortIds: ["more"],
            dataGetter: (license: SoftwareLicense) => {
                return license;
            },
            options: {
                sort: false,
                filter: false,
                customBodyRender: this.menuButtonRenderer,
            },
        },
    ];

    public readonly state: SoftwareLicensesState = {
        softwareLicenseSortColumnIndex: -1,
        menuAnchor: null,
        menuGenerateLicense: true,
    };

    private moreClick(value: SoftwareLicense): ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined {
        return (event: any) => this.setState({
            selectedLicense: value,
            menuAnchor: event.currentTarget,
            menuGenerateLicense: true,
        });
    }

    private loadLicenses(offset: number, limit: number, sorts: ReadonlyArray<QuerySort>, search?: string | null): void {
        this.props.dispatch(networkActions.getSoftwareLicensesList(this.props.env, offset, limit, sorts, search));
    }

    public componentDidMount(): void {
        if (this.props.env) {
            this.props.dispatch(networkActions.getSoftwareLicensesListStats(this.props.env, this.props.offset, this.props.limit, []));
            this.loadLicenses(this.props.offset, this.props.limit, []);
        }
    }

    public componentDidUpdate(prevProps: Readonly<SoftwareLicensesProps>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.env !== this.props.env || this.props.shouldUpdateList) {
            this.props.dispatch(networkActions.getSoftwareLicensesListStats(this.props.env, this.props.offset, this.props.limit, []));
            this.loadLicenses(this.props.offset, this.props.limit, []);
        }
        if (this.props.signedLicense && this.props.signedLicense !== prevProps.signedLicense) {
            this.handleDownload();
        }
    }

    private readonly handleDownload = () => {
        if (!this.props.signedLicense) { return; }

        const json = JSON.stringify(this.props.signedLicense, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = "eniq_software_license.bin";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    public render(): any {
        const handleClose = () => {
            this.setState({ menuAnchor: null });
        };
        const handleGenerateLicense = () => {
            const selectedLicense = this.state.selectedLicense;
            if (selectedLicense == null) { return; }
            this.props.dispatch(networkActions.generateSoftwareLicense(this.props.env, selectedLicense.esid));
            this.setState({
                selectedLicense: undefined,
                menuAnchor: null,
            });
        };

        const optionsSoftwareLicenses: MUIDataTableOptions = {
            filterType: "dropdown",
            responsive: "stacked",
            selectableRows: "none",
            pagination: this.props.limit < this.props.count,
            sort: true,
            filter: false,
            print: false,
            rowsPerPage: this.props.limit,
            rowsPerPageOptions: [10, 25, 50, 100, 150, 200, 250, 300, 400, 500, 1000],
            count: this.props.count,
            serverSide: true,
            downloadOptions: {
                filename: "report.csv",
            },
            onTableChange: (action: any, tableState: MUIDataTableState) => {
                if (action === "sort" || action === "changePage" || action === "search" || action === "changeRowsPerPage") {
                    const columnState = tableState.columns.find((item: any) => item.sortDirection !== "none");
                    let activeColumnIndex = -1;
                    if (tableState.activeColumn !== null) {
                        activeColumnIndex = Number(tableState.activeColumn);
                    }
                    const column = this.tableColumns[activeColumnIndex];
                    const sorts = columnState ? column!.sortIds.map((id: string) => new QuerySort(id, columnState.sortDirection!)) : [];
                    this.loadLicenses(tableState.page * tableState.rowsPerPage, tableState.rowsPerPage, sorts, tableState.searchText);
                    this.setState({
                        softwareLicenseSortColumnIndex: activeColumnIndex,
                        softwareLicenseSortDirection: columnState?.sortDirection,
                    });
                }
            },
        };

        const error = this.props.errorMessage ? (
            <Notifier
                message={this.props.errorMessage}
                open={true}
                variant={NotifierVariant.ERROR}
            />
        ) : null;

        const softwareLicenseData: any = this.props.licenses
            .map((license: SoftwareLicense): ReadonlyArray<any> => {
                return this.tableColumns.map((column: any) => column.dataGetter(license));
            })
            .toArray();

        let menuItem;
        if (this.state.menuGenerateLicense) {
            menuItem = <MenuItem onClick={handleGenerateLicense}>Generate License</MenuItem>;
        }
        return (
            <main className="purchases">
                <div className="progressContainer">
                    {this.props.progress && <LinearProgress />}
                </div>
                <div className="tableFree">
                    <MUIDataTable
                        title={"ENiQ Software Licenses"}
                        columns={this.tableColumns.slice()}
                        data={softwareLicenseData}
                        options={optionsSoftwareLicenses}
                    />
                </div>
                {error}
                <Menu
                    id="more-menu"
                    anchorEl={this.state.menuAnchor}
                    open={Boolean(this.state.menuAnchor)}
                    onClose={handleClose}
                >
                    {menuItem}
                </Menu>
            </main>
        );
    }
}

export default connect(
    (state: RootState, ownProps: SoftwareLicensesProps): SoftwareLicensesProps => {
        const softwareState = getState(state);
        const canModifyRecords = true;
        return {
            env: ownProps.env,
            progress: softwareState.progress,
            licenses: softwareState.licenses,
            offset: softwareState.offset,
            limit: softwareState.limit,
            count: softwareState.count,
            errorMessage: softwareState.error ? softwareState.error.message : null,
            shouldUpdateList: softwareState.shouldUpdateList,
            canModifyRecords: canModifyRecords,
            signedLicense: softwareState.signedLicense
        };
    }
)(SoftwareLicensesComponent);
