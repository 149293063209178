import * as React from "react";
import { Dialog } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { Formik, FormikValues, Form } from "formik";
import * as yup from "yup";

interface AddMobileKeyUserProps {
    readonly open: boolean;
    readonly classes?: any;

    onClose(): void;
    onSubmit(mastercard: string, ownerAccountId: string): void;
}

const form = {
    mastercard: "",
    ownerAccountId: ""
};

const formValidationSchema = yup.object().shape({
    mastercard: yup.string()
        .required("Mastercard is required.")
        .matches(/^[0-9]*$/, "Wrong format.")
        .min(13, "Mastercard number normally should be more then 13 digits."),
    ownerAccountId: yup.string()
        .required("Owner Account Id is required.")
        .uuid()
});

class AddMobileKeyUserComponent extends React.Component<AddMobileKeyUserProps> {

    private readonly handleClose = () => {
        this.props.onClose();
    }

    private readonly handleSubmit = (values: FormikValues) => {
        this.props.onSubmit(values.mastercard, values.ownerAccountId);
    }

    public render(): any {
        return <Dialog open={true} aria-labelledby="form-dialog-title" onClose={this.handleClose}>
            <DialogTitle id="form-dialog-title">Add MobileKey User</DialogTitle>
            <Formik
                initialValues={form}
                validationSchema={formValidationSchema}
                onSubmit={this.handleSubmit}
            >
                {(formik) => (
                    <Form id="add-mobilekey-user">
                        <DialogContent>
                            <DialogContentText>
                                To add new MobileKey user enter mastercard number and owner account id
                            </DialogContentText>
                            <TextField
                                InputLabelProps={{
                                    classes: {
                                        root: this.props.classes.formLabelRoot,
                                        focused: this.props.classes.formLabelFocused,
                                        error: this.props.classes.erroredLabel
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        underline: this.props.classes.inputUnderline,
                                        error: this.props.classes.error
                                    },
                                }}
                                value={formik.values.mastercard}
                                onChange={formik.handleChange}
                                error={formik.touched.mastercard && Boolean(formik.errors.mastercard)}
                                helperText={formik.touched.mastercard && formik.errors.mastercard}
                                autoFocus
                                margin="dense"
                                id="mastercard"
                                name="mastercard"
                                label="Mastercard"
                                type="text"
                                fullWidth
                            />
                            <TextField
                                InputLabelProps={{
                                    classes: {
                                        root: this.props.classes.formLabelRoot,
                                        focused: this.props.classes.formLabelFocused,
                                        error: this.props.classes.erroredLabel
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        underline: this.props.classes.inputUnderline,
                                        error: this.props.classes.error
                                    },
                                }}
                                value={formik.values.ownerAccountId}
                                onChange={formik.handleChange}
                                error={formik.touched.ownerAccountId && Boolean(formik.errors.ownerAccountId)}
                                helperText={formik.touched.ownerAccountId && formik.errors.ownerAccountId}
                                margin="dense"
                                id="ownerAccountId"
                                name="ownerAccountId"
                                label="Owner Account Id"
                                type="text"
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose}>Cancel</Button>
                            <Button classes={{ root: this.props.classes.trackButton }} type="submit">Add User</Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>;
    }
}

const styles = {
    trackButton: {
        color: "#00a0de"
    },
    inputUnderline: {
        "&:after": {
            borderBottomColor: "#00a0de"
        },
        "&:error:after": {
            borderBottomColor: red[500]
        },
    },
    formLabelRoot: {
        "&$formLabelFocused": { color: "#00a0de" },
    },
    formLabelFocused: {
    },
    erroredLabel: {
        "&$formLabelFocused": { color: red[500] },
    }
};

export default withStyles(styles)(AddMobileKeyUserComponent);
