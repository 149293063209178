import * as React from "react";
import RootState from "../../states/RootState";
import { connect } from "react-redux";
import { BaseComponent } from "../BaseComponent";
import { Button } from "@material-ui/core";
import RestoreDefaultMobileKeyPasswordComponent from "./RestoreDefaultMobileKeyPasswordComponent";
import { networkActions } from "actions/network";
import Environment from "models/Environment";
import { appBarActions } from "actions/appbar";
import { List } from "immutable";
import Notifier, { NotifierVariant } from "../../tools/Notifier";
import { getState } from "reducers/ToolsReducer";
import RecreateMobileKeyUserComponent from "./RecreateMobileKeyUserComponent";

interface ToolsProps {
    readonly env: Environment;
    readonly errorMessage: string | null;
    readonly successMessage: string;
}

interface ToolsState {
    readonly showRestorePasswordDialog: boolean;
    readonly recreateUserDialogOpen: boolean;
}

const envs = List([
    {
        id: "dev",
        title: "Development"
    },
    {
        id: "prod",
        title: "Production"
    }
]);

export class ToolsComponent extends BaseComponent<ToolsProps, ToolsState> {

    public readonly state: ToolsState = {
        showRestorePasswordDialog: false,
        recreateUserDialogOpen: false,
    };

    public componentWillMount(): void {
        let env = this.props.match.params.env;
        if (!env) {
            env = envs.first({ id: "dev" }).id;
        }
        const location = `/tools/${env}`;
        if (this.props.history.location.pathname !== location) {
            this.props.history.replace(location);
        }
        this.props.dispatch(appBarActions.setTitle("Tools"));
        this.props.dispatch(appBarActions.setEnvs(envs));
        this.props.dispatch(appBarActions.selectEnv(envs.find((value) => value.id === env)));
    }

    public componentDidUpdate(prevProps: Readonly<ToolsProps>, prevState: Readonly<ToolsState>, snapshot?: any): void {
        const env = this.props.match.params.env;
        if (env && this.props.env.id !== env) {
            this.props.history.replace(`/tools/${this.props.env.id}`);
        }
    }

    private readonly restoreDefaultPasswordAction = () => {
        this.setState({
            showRestorePasswordDialog: true,
            recreateUserDialogOpen: false
        });
    }

    private readonly openRecreateUserDialog = () => {
        this.setState({
            showRestorePasswordDialog: false,
            recreateUserDialogOpen: true
        });
    }

    private readonly submitRestore = (masterCardNumber: string, masterCardUid: string) => {
        this.props.dispatch(networkActions.restoreMobileKeyDefaultPassword(masterCardNumber, masterCardUid));
    }

    private readonly submitRecreateUser = (masterCardNumber: string, masterCardUid: string) => {
        this.props.dispatch(networkActions.recreateMobileKeyUser(masterCardNumber, masterCardUid));
        this.setState({
            recreateUserDialogOpen: false
        });
    }

    private readonly closeDialog = () => {
        this.setState({
            showRestorePasswordDialog: false,
            recreateUserDialogOpen: false
        });
    }

    public render(): any {
        const restorePasswordDialog = this.state.showRestorePasswordDialog
            ? <RestoreDefaultMobileKeyPasswordComponent
                onRestore={this.submitRestore}
                onClose={this.closeDialog}
            /> : null;

        const recreateUserDialog = this.state.recreateUserDialogOpen
            ? <RecreateMobileKeyUserComponent
                open={this.state.recreateUserDialogOpen}
                onSubmit={this.submitRecreateUser}
                onClose={this.closeDialog}
            />
            : null;

        const errorNotifier = this.props.errorMessage
            ? <Notifier
                message={this.props.errorMessage}
                open={true}
                variant={NotifierVariant.ERROR}
            /> : null;

        const successNotifer = Boolean(this.props.successMessage)
            ? <Notifier
                message={this.props.successMessage}
                open={true}
                variant={NotifierVariant.SUCCESS}
            /> : null;
        const restoreDefaultPassword = process.env.API_ENDPOINT?.includes("dev")
            ? [
                <Button
                    color="primary"
                    variant="contained"
                    onClick={this.restoreDefaultPasswordAction}
                >
                    Restore Default Mobile Key Password
                </Button>,
                <Button
                    color="primary"
                    variant="contained"
                    onClick={this.openRecreateUserDialog}
                >
                    Recreate MobileKey User
                </Button>
            ]
            : null;
        return <main className="tools">
            {restoreDefaultPassword}
            {restorePasswordDialog}
            {recreateUserDialog}
            {errorNotifier}
            {successNotifer}
        </main>;
    }
}

export default connect((state: RootState): ToolsProps => {
    const toolsState = getState(state);
    return {
        env: state.appBar.selectedEnvironment!,
        successMessage: toolsState.error ? "" : toolsState.successMessage,
        errorMessage: toolsState.error ? toolsState.error.message : null
    };
})(ToolsComponent);
