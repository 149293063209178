import * as React from "react";
import Notifier, { NotifierVariant } from "../../../tools/Notifier";
import TrackPurchaseComponent from "./TrackPurchaseComponent";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import { List } from "immutable";
import Purchase from "../../../models/Purchase";
import * as moment from "moment";
import { BugReportOutlined, ShopOutlined, MoreVert } from "@material-ui/icons";
import { networkActions, QuerySort } from "../../../actions/network";
import PurchasesFilterToolbar from "./PurchasesFilterToolbar";
import RootState from "../../../states/RootState";
import { connect } from "react-redux";
import { getState } from "../../../reducers/PurchasesReducer";
import { BaseComponent } from "../../BaseComponent";
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import { Button, IconButton, Menu, MenuItem } from "@material-ui/core";
import PurchasesTableSearch from "./PurchasesTableSearch";
import CreatePurchaseComponent from "./CreatePurchaseComponent";
import FreePurchase from "models/FreePurchase";
import AddMobileKeyUserComponent from "./AddMobileKeyUserComponent";
import { purchasesActions } from "../../../actions/purchases";

const dateFormat = (date: Date) => moment(date).utc().format("DD.MM.YYYY HH:mm:ss'Z");

interface PurchasesProps {
    readonly env: string;
    readonly progress: boolean;
    readonly purchases: List<Purchase>;
    readonly freePurchases: List<FreePurchase>;
    readonly offset: number;
    readonly limit: number;
    readonly count: number;
    readonly freePurchasesOffset: number;
    readonly freePurchasesLimit: number;
    readonly freePurchasesCount: number;
    readonly errorMessage: string | null;
    readonly canTrackPurchase: boolean;
    readonly shouldUpdateList: boolean;
    readonly notification: string;
}

interface PurchasesState {
    readonly sortColumnIndex: number;
    readonly sortDirection?: "asc" | "desc" | "none";
    readonly selectedPurchase?: Purchase;
    readonly menuAnchor: null;
    readonly menuTrackPurchase: boolean;
    readonly menuDeletePurchase: boolean;
    readonly trackDialogOpen: boolean;
    readonly createDialogOpen: boolean;
    readonly addMobileKeyUserDialogOpen: boolean;
    readonly freePurchaseSortColumnIndex: number;
    readonly freePurchaseSortDirection?: "asc" | "desc" | "none";
}

const NULL = "NULL";

const renderNullableTextCell = (value: string, tableMeta: any, updateValue: any): string | React.ReactNode => {
    if (value === NULL) {
        return <span className="tableNullCell">{value}</span>;
    } else {
        return value;
    }
};

const renderTestCell = (value: string, tableMeta: any, updateValue: any): string | React.ReactNode => {
    const cell = renderNullableTextCell(value, tableMeta, updateValue);
    if (typeof cell === "object") {
        return cell;
    } else {
        if (value === "true") {
            return <BugReportOutlined />;
        } else {
            return <ShopOutlined />;
        }
    }
};

type PurchaseTableColumn = MUIDataTableColumn & {
    dataGetter(item: any,): any;
    readonly sortIds: ReadonlyArray<string>;
};

export class PurchasesComponent extends BaseComponent<PurchasesProps, PurchasesState> {

    public readonly state: PurchasesState = {
        sortColumnIndex: -1,
        menuAnchor: null,
        trackDialogOpen: false,
        createDialogOpen: false,
        menuTrackPurchase: false,
        menuDeletePurchase: false,
        freePurchaseSortColumnIndex: -1,
        addMobileKeyUserDialogOpen: false
    };

    private readonly menuButtonRenderer = (value: Purchase, tableMeta: any, updateValue: any): string | React.ReactNode => {
        if (value === undefined) {
            return <span className="tableEmptyCell" />;
        } else {
            return <div className="button_container">
                <IconButton
                    size="small"
                    aria-label="more"
                    onClick={this.moreClick(value)}
                >
                    <MoreVert />
                </IconButton>
            </div>;
        }
    }

    private moreClick(value: Purchase): ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined {
        function isFreePurchase(purchaseValue: Purchase): boolean {
            return !purchaseValue.hasOwnProperty("state");
        }

        return (event: any) => this.setState({
            selectedPurchase: value,
            menuAnchor: event.currentTarget,
            menuDeletePurchase: isFreePurchase(value),
            menuTrackPurchase: value.state === "INITIALIZED"
        });
    }

    private loadPurchases(offset: number, limit: number, sorts: ReadonlyArray<QuerySort>, search?: string | null): void {
        this.props.dispatch(networkActions.getPurchasesList(this.props.env, offset, limit, sorts, search));
    }

    private loadFreePurchases(offset: number, limit: number, sorts: ReadonlyArray<QuerySort>, search?: string | null): void {
        this.props.dispatch(networkActions.getFreePurchasesList(this.props.env, offset, limit, sorts, search));
    }

    private trackPurchase(purchaseId: number, googleToken: string, enteredPrice: number, enteredCurrency: string): void {
        this.props.dispatch(networkActions.trackPurchase(this.props.env, purchaseId, googleToken, enteredPrice, enteredCurrency));
    }

private createFreePurchase(mastercard: String, productId: string, expiresDate: number, comment: string): void {
    this.props.dispatch(networkActions.createFreePurchase(this.props.env, mastercard, productId, expiresDate, comment))
    }

    private addMobuleKeyUser(mastercard: string, ownerAccountID: string): void {
        this.props.dispatch(networkActions.addMobileKeyUser(this.props.env, mastercard, ownerAccountID));
    }

    private readonly handleClearNotification = () => {
        this.props.dispatch(purchasesActions.clearPurchasesNotification());
    }

    public componentWillMount(): void {
        if (this.props.env) {
            this.props.dispatch(networkActions.getPurchasesStats(this.props.env));
            this.props.dispatch(networkActions.getFreePurchasesStats(this.props.env));
            this.loadFreePurchases(this.props.freePurchasesOffset, this.props.freePurchasesLimit, []);
            this.loadPurchases(this.props.offset, this.props.limit, []);
        }
    }

    public componentDidUpdate(prevProps: Readonly<PurchasesProps>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.env !== this.props.env || this.props.shouldUpdateList) {
            this.props.dispatch(networkActions.getPurchasesStats(this.props.env));
            this.props.dispatch(networkActions.getFreePurchasesStats(this.props.env));
            this.loadFreePurchases(this.props.freePurchasesOffset, this.props.freePurchasesLimit, []);
            this.loadPurchases(this.props.offset, this.props.limit, []);
        }
    }

    public render(): any {
        const handleClose = () => {
            this.setState({ menuAnchor: null });
        };
        const handleTrack = () => {
            this.setState({
                menuAnchor: null,
                trackDialogOpen: true
            });
        };
        const handleRemove = () => {
            const selectedPurchase = this.state.selectedPurchase;
            if (selectedPurchase == null) { return; }
            this.props.dispatch(networkActions.removeFreePurchase(this.props.env, selectedPurchase.id));
            this.setState({
                selectedPurchase: undefined,
                menuAnchor: null
            });
        };
        const handleClearInfo = () => {
            this.props.dispatch(networkActions.clearPurchaseInfo(this.props.env, this.state.selectedPurchase?.id));
            this.setState({ menuAnchor: null });
        };

        if (this.state.sortColumnIndex != null && this.state.sortColumnIndex >= 0) {
            this.tableColumns.get(this.state.sortColumnIndex)!.options!.sortDirection = this.state.sortDirection;
        }
        if (this.state.freePurchaseSortColumnIndex != null && this.state.freePurchaseSortColumnIndex >= 0) {
            this.freePurchasesTableColumns.get(this.state.freePurchaseSortColumnIndex)!.options!.sortDirection = this.state.freePurchaseSortDirection;
        }
        const optionsFreePurchases: MUIDataTableOptions = {
            filterType: "dropdown",
            responsive: "stacked",
            selectableRows: "none",
            pagination: this.props.freePurchasesLimit < this.props.freePurchasesCount,
            sort: true,
            filter: false,
            print: false,
            rowsPerPage: this.props.freePurchasesLimit,
            rowsPerPageOptions: [10, 25, 50, 100, 150, 200, 250, 300, 400, 500, 1000],
            count: this.props.freePurchasesCount,
            serverSide: true,
            downloadOptions: {
                filename: "report_free.csv"
            },
            onTableChange: (action: any, tableState: MUIDataTableState) => {
                if (action === "sort" || action === "changePage" || action === "search" || action === "changeRowsPerPage") {
                    // @ts-ignore
                    const column = this.freePurchasesTableColumns.get(tableState.activeColumn as number);
                    const columnState = tableState.columns.find((item: any) => item.sortDirection !== "none");
                    const sorts = columnState ? column!.sortIds.map((id: string) => new QuerySort(id, columnState.sortDirection!)) : [];
                    this.loadFreePurchases(tableState.page * tableState.rowsPerPage, tableState.rowsPerPage, sorts, tableState.searchText);
                    this.setState({
                        // @ts-ignore
                        freePurchaseSortColumnIndex: tableState.activeColumn as number,
                        freePurchaseSortDirection: columnState?.sortDirection,
                    });
                }
            },
        };
        const options: MUIDataTableOptions = {
            filterType: "dropdown",
            responsive: "stacked",
            selectableRows: "none",
            pagination: this.props.limit < this.props.count,
            sort: true,
            filter: false,
            print: false,
            rowsPerPage: this.props.limit,
            rowsPerPageOptions: [10, 25, 50, 100, 150, 200, 250, 300, 400, 500, 1000],
            count: this.props.count,
            serverSide: true,
            downloadOptions: {
                filename: "report.csv"
            },
            onTableChange: (action: any, tableState: MUIDataTableState) => {
                if (action === "sort" || action === "changePage" || action === "search" || action === "changeRowsPerPage") {
                    // @ts-ignore
                    const column = this.tableColumns.get(tableState.activeColumn as number);
                    const columnState = tableState.columns.find((item: any) => item.sortDirection !== "none");
                    const sorts = columnState ? column!.sortIds.map((id: string) => new QuerySort(id, columnState.sortDirection!)) : [];
                    this.loadPurchases(tableState.page * tableState.rowsPerPage, tableState.rowsPerPage, sorts, tableState.searchText);
                    this.setState({
                        // @ts-ignore
                        sortColumnIndex: tableState.activeColumn as number,
                        sortDirection: columnState?.sortDirection,
                    });
                }
            },
            customToolbar: () => <PurchasesFilterToolbar />,
            searchPlaceholder: "Type master card number and press Enter",
            // tslint:disable-next-line:max-params no-shadowed-variable
            customSearchRender: (searchText, handleSearch, hideSearch, options) => {
                return <PurchasesTableSearch
                    searchText={searchText}
                    onSearch={handleSearch}
                    onHide={hideSearch}
                    options={options}
                />;
            },
        };

        const error = this.props.errorMessage
            ? <Notifier
                message={this.props.errorMessage}
                open={true}
                variant={NotifierVariant.ERROR}
            />
            : null;
        const notification = this.props.notification
            ? <Notifier
                message={this.props.notification}
                open={true}
                onClose={this.handleClearNotification}
                variant={NotifierVariant.NORMAL}
            />
            : null;
        const dialog = this.state.trackDialogOpen ? <TrackPurchaseComponent
            open={this.state.trackDialogOpen}
            selectedPurchaseId={this.state.selectedPurchase?.id}
            onSubmit={this.submitTrack()}
            onClose={this.closeDialog()}
        /> : null;
        const createPurchaseDialog = this.state.createDialogOpen ? <CreatePurchaseComponent
            open={this.state.createDialogOpen}
            onSubmit={this.submitCreate()}
            onClose={this.closeDialog()}
        /> : null;
        const addMobileKeyUserDialog = this.state.addMobileKeyUserDialogOpen ? <AddMobileKeyUserComponent
            open={this.state.addMobileKeyUserDialogOpen}
            onSubmit={this.submitMobileKeyUser()}
            onClose={this.closeDialog()}
        /> : null;
        const buttonAddPurchase = this.props.canTrackPurchase ?
            <Button
                className="addPurchase"
                color="primary"
                variant="contained"
                size="large"
                onClick={
                    // tslint:disable-next-line:jsx-no-lambda
                    () => {
                        this.setState({
                            menuAnchor: null,
                            createDialogOpen: true
                        });
                    }}
            >
                Add purchase
            </Button>
            : null;
        const tableColumnsFixed = this.props.canTrackPurchase ? this.tableColumns : this.tableColumns.skipLast(1);
        const tableData: any = this.props.purchases.map((purchase: Purchase): ReadonlyArray<any> => {
            return tableColumnsFixed.map((column: any) => column.dataGetter(purchase)).toArray();
        }).toArray();

        const freePurchaseData: any = this.props.freePurchases.map((purchase: FreePurchase): ReadonlyArray<any> => {
            return this.freePurchasesTableColumns.map((column: any) => column.dataGetter(purchase)).toArray();
        }).toArray();

        let menuItem;
        if (this.state.menuDeletePurchase) {
            menuItem = <MenuItem onClick={handleRemove}>Remove</MenuItem>;
        } else if (this.state.menuTrackPurchase) {
            menuItem = <MenuItem onClick={handleTrack}>Track</MenuItem>;
        } else {
            menuItem = <MenuItem onClick={handleClearInfo}>Clear Info</MenuItem>;
        }

        return <main className="purchases">
            <div className="progressContainer">
                {this.props.progress && <LinearProgress />}
            </div>
            {buttonAddPurchase}
            <Button
                className="addMobileKeyUser"
                color="primary"
                variant="contained"
                size="large"
                onClick={
                    // tslint:disable-next-line:jsx-no-lambda
                    () => {
                        this.setState({
                            menuAnchor: null,
                            addMobileKeyUserDialogOpen: true
                        });
                    }}
            >
                Add MobileKey User
            </Button>
            <div className="tableFree">
                <MUIDataTable
                    title={"Manual licenses"}
                    columns={this.freePurchasesTableColumns.toArray()}
                    data={freePurchaseData}
                    options={optionsFreePurchases}
                />
            </div>
            <MUIDataTable
                title={"Google licenses"}
                columns={tableColumnsFixed.toArray()}
                data={tableData}
                options={options}
            />
            {error}
            {notification}
            {dialog}
            {createPurchaseDialog}
            {addMobileKeyUserDialog}
            <Menu
                id="more-menu"
                anchorEl={this.state.menuAnchor}
                open={Boolean(this.state.menuAnchor)}
                onClose={handleClose}
            >
                {menuItem}
            </Menu>
        </main>;
    }

    private readonly freePurchasesTableColumns: List<PurchaseTableColumn> = List.of(
        {
            name: "ID",
            sortIds: ["id"],
            dataGetter: (purchase: FreePurchase) => purchase.id,
            options: {
                filter: false,
            }
        },
        {
            name: "Master Card",
            sortIds: ["mastercard"],
            dataGetter: (purchase: FreePurchase) => purchase.mastercard,
            options: {
                filter: false,
            }
        },
        {
            name: "Product ID",
            sortIds: ["google_product_id", "google_product_id_voucher"],
            dataGetter: (purchase: FreePurchase) => purchase.googleProductId,
            options: {
                filter: false,
            }
        },
        {
            name: "Create Date",
            sortIds: ["created_at"],
            dataGetter: (purchase: FreePurchase) => dateFormat(purchase.createdAt),
            options: {
                filter: false,
            }
        },
        {
            name: "Expiry Date",
            sortIds: ["expires_at"],
            dataGetter: (purchase: FreePurchase) => dateFormat(purchase.expiresAt),
            options: {
                filter: false,
            }
        },
        {
            name: "Comment",
            sortIds: ["comment"],
            dataGetter: (purchase: FreePurchase) => purchase.comment,
            options: {
                filter: false,
            }
        },
        {
            name: "",
            sortIds: ["more"],
            dataGetter: (purchase: FreePurchase) => purchase,
            options: {
                sort: false,
                filter: false,
                customBodyRender: this.menuButtonRenderer
            }
        }
    );

    private readonly tableColumns: List<PurchaseTableColumn> = List.of(
        {
            name: "ID",
            sortIds: ["id"],
            dataGetter: (purchase: Purchase) => purchase.id,
            options: {
                filter: false,
            }
        },
        {
            name: "Master Card",
            sortIds: ["mastercard"],
            dataGetter: (purchase: Purchase) => purchase.mastercard,
            options: {
                filter: false,
            }
        },
        {
            name: "Product ID",
            sortIds: ["google_product_id", "google_product_id_voucher"],
            dataGetter: (purchase: Purchase) => purchase.googleProductId,
            options: {
                filter: false,
            }
        },
        {
            name: "Create Date",
            sortIds: ["created_at"],
            dataGetter: (purchase: Purchase) => dateFormat(purchase.createdAt),
            options: {
                filter: true,
                display: "false",
            }
        },
        {
            name: "Update Date",
            sortIds: ["updated_at"],
            dataGetter: (purchase: Purchase) => dateFormat(purchase.updatedAt),
            options: {
                filter: false,
            }
        },
        {
            name: "State",
            sortIds: ["state"],
            dataGetter: (purchase: Purchase) => purchase.state,
            options: {
                filter: false,
            }
        },
        {
            name: "Revoke Reason",
            sortIds: ["revoke_reason"],
            dataGetter: (purchase: Purchase) => purchase.revokeReason != null ? purchase.revokeReason : NULL,
            options: {
                filter: false,
                display: "false",
                customBodyRender: renderNullableTextCell
            }
        },
        {
            name: "Exp Date",
            sortIds: ["expires_date"],
            dataGetter: (purchase: Purchase) => purchase.expiresDate != null ? dateFormat(purchase.expiresDate) : NULL,
            options: {
                filter: false,
                customBodyRender: renderNullableTextCell
            }
        },
        {
            name: "Voucher",
            sortIds: ["voucher"],
            dataGetter: (purchase: Purchase) => purchase.voucher != null ? purchase.voucher : NULL,
            options: {
                filter: false,
                customBodyRender: renderNullableTextCell
            }
        },
        {
            name: "Google Order ID",
            sortIds: ["google_order_id"],
            dataGetter: (purchase: Purchase) => purchase.googleOrderId != null ? purchase.googleOrderId : NULL,
            options: {
                filter: false,
                display: "false",
                customBodyRender: renderNullableTextCell
            }
        },
        {
            name: "Google Token",
            sortIds: ["google_token"],
            dataGetter: (purchase: Purchase) => purchase.googleToken,
            options: {
                filter: false,
                display: "false",
                customBodyRender: renderNullableTextCell
            }
        },
        {
            name: "Price",
            sortIds: ["price"],
            dataGetter: (purchase: Purchase) => (purchase.price != null ? purchase.price + " " + purchase.currency : NULL),
            options: {
                filter: false,
                customBodyRender: renderNullableTextCell
            }
        },
        {
            name: "Version",
            sortIds: ["version"],
            dataGetter: (purchase: Purchase) => (purchase.appVersion != null ? purchase.appVersion : NULL),
            options: {
                filter: false,
                display: "false",
                customBodyRender: renderNullableTextCell
            }
        },
        {
            name: "Test",
            sortIds: ["google_license_testing"],
            dataGetter: (purchase: Purchase) => purchase.test != null ? purchase.test.toString() : NULL,
            options: {
                filter: false,
                customBodyRender: renderTestCell
            }
        },
        {
            name: "Linked ID",
            sortIds: ["linked_purchase_id"],
            dataGetter: (purchase: Purchase) => purchase.linkedId === 0 ? "" : purchase.linkedId,
            options: {
                filter: false,
            }
        },
        {
            name: "",
            sortIds: ["more"],
            dataGetter: (purchase: Purchase) => {
                let value = this.props.canTrackPurchase ? purchase : undefined;
                if (purchase.state === "PURCHASED" && this.props.env === "prod") {
                    value = undefined;
                }
                return value;
            },
            options: {
                sort: false,
                filter: false,
                customBodyRender: this.menuButtonRenderer
            }
        }
    );

    private closeDialog(): (() => void) {
        return () => this.setState({
            selectedPurchase: undefined,
            trackDialogOpen: false,
            createDialogOpen: false,
            addMobileKeyUserDialogOpen: false
        });
    }

    private submitTrack(): ((enteredToken: string, enteredPrice: number, enteredCurrency: string) => void) {
        return (enteredToken, enteredPrice, enteredCurrency) => {
            this.trackPurchase(this.state.selectedPurchase!.id, enteredToken, enteredPrice, enteredCurrency);
            this.setState({
                selectedPurchase: undefined,
                trackDialogOpen: false
            });
        };
    }

    private submitCreate(): ((mastercard: string, productId: string, expiresDate: number, comment: string) => void) {
        return (mastercard, productId, expiresDate, comment) => {
            this.createFreePurchase(mastercard, productId, expiresDate, comment);
            this.setState({
                selectedPurchase: undefined,
                createDialogOpen: false
            });
        };
    }

    private submitMobileKeyUser(): ((mastercard: string, ownerAccountId: string) => void) {
        return (mastercard, ownerAccountId) => {
            this.addMobuleKeyUser(mastercard, ownerAccountId);
            this.setState({
                selectedPurchase: undefined,
                addMobileKeyUserDialogOpen: false
            });
        };
    }
}

export default connect((state: RootState, ownProps: PurchasesProps): PurchasesProps => {
    const purchasesState = getState(state);
    let canTrackPurchase = true;
    if (ownProps.env === "prod") {
        canTrackPurchase = state.session?.role === "admin";
    }
    return {
        env: ownProps.env,
        progress: purchasesState.progress,
        purchases: purchasesState.purchases,
        freePurchases: purchasesState.freePurchases,
        offset: purchasesState.offset,
        limit: purchasesState.limit,
        count: purchasesState.count,
        freePurchasesCount: purchasesState.freePurchasesCount,
        freePurchasesLimit: purchasesState.freePurchasesLimit,
        freePurchasesOffset: purchasesState.freePurchasesOffset,
        errorMessage: purchasesState.error ? purchasesState.error.message : null,
        canTrackPurchase: canTrackPurchase,
        shouldUpdateList: purchasesState.shouldUpdateList,
        notification: purchasesState.notification
    };
})(PurchasesComponent);

// TODO: this is a huge file. Please, separate it to different components.
