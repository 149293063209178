import * as React from "react";
import { Route, Switch } from "react-router";
import AppBarComponent from "./AppBarComponent";
import { BrowserRouter, Redirect } from "react-router-dom";
import { Drawer } from "@material-ui/core";
import MenuComponent, { NavigationItem } from "./MenuComponent";
import LicensingComponent from "./licensing/LicensingComponent";
import FirmwaresComponent from "./firmwares/FirmwaresComponent";
import AuthInfoContoller from "./auth/AuthInfoComponent";
import EniqSoftwareComponent from "./software/SoftwareComponent";
import RootState from "states/RootState";
import { connect } from "react-redux";
import LicensingIconComponent from "./icon/LicensingIconComponent";
import AuthInfoIconComponent from "./icon/AuthInfoIconComponent";
import FirmwareIconComponent from "./icon/FirmwareIconComponent";
import ToolsIconComponent from "./icon/ToolsIconComponent";
import ToolsComponent from "./tools/ToolsComponent";

interface MainComponentProps {
    readonly firmwareOnly: boolean;
    readonly showOnlySoftware: boolean;
}

interface MainComponentState {
    readonly drawerOpen: boolean;
}

const navList: ReadonlyArray<NavigationItem> = [
    {
        icon: <LicensingIconComponent />,
        text: "Licensing",
        location: "/licensing",
        selected: false,
    },
    {
        icon: <AuthInfoIconComponent />,
        text: "Auth Info",
        location: "/auth_info",
        selected: false,
    },
    {
        icon: <FirmwareIconComponent />,
        text: "Firmwares",
        location: "/firmwares",
        selected: false,
    },
    {
        icon: <ToolsIconComponent />,
        text: "Tools",
        location: "/tools",
        selected: false,
    },
];

const navListFW: ReadonlyArray<NavigationItem> = [
    {
        icon: <FirmwareIconComponent />,
        text: "Firmwares",
        location: "/firmwares",
        selected: false,
    },
];

const navListSoftware: ReadonlyArray<NavigationItem> = [
    {
        icon: <LicensingIconComponent />,
        text: "ENiQ Software",
        location: "/eniq_software",
        selected: false,
    },
];

export class MainComponent extends React.Component<MainComponentProps, MainComponentState> {

    public readonly state: MainComponentState = {
        drawerOpen: false,
    };

    private toggleDrawer(open: boolean): void {
        this.setState({
            drawerOpen: open
        });
    }

    public render(): any {
        const handleClose = () => {
            this.toggleDrawer(false);
        };
        const handleMenuClick = () => {
            this.toggleDrawer(true);
        };
        const renderMenu = (props: any) => {
            const handleOnNavigate = (push: string) => {
                if (!location.pathname.startsWith(push)) {
                    props.history.push(push);
                }
                this.toggleDrawer(false);
            };
            const selected = navList.findIndex((value) => props.location.pathname.startsWith(value.location));
            var navigations;
            if (this.props.showOnlySoftware) {
                navigations = navListSoftware;
            } else if (this.props.firmwareOnly) {
                navigations = navListFW;
            } else {
                navigations = navList;
            }

            return <MenuComponent
                navigations={navigations}
                selected={selected >= 0 ? selected : 0}
                onNavigate={handleOnNavigate}
            />;
        };
        const redirect = this.props.firmwareOnly ? "/firmwares" : "/licensing"
        return <BrowserRouter>
            <div className="content">
                <AppBarComponent onMenuClick={handleMenuClick} />
                <Drawer
                    open={this.state.drawerOpen}
                    onClose={handleClose}
                >
                    <Route children={renderMenu} />
                </Drawer>
                <main>
    <Switch>
        {this.props.showOnlySoftware ? (
            <>
            <Route exact path="/">
                <Redirect to={"/eniq_software/dev"} />
            </Route>              
            <Route path={["/eniq_software/:env?/:tab?"]} component={EniqSoftwareComponent} />
            </>
        ) : (
            <>
        <Route exact path="/">
            <Redirect to={redirect} />
        </Route>            
                 <Route path={["/licensing/:env?/:tab?"]} component={LicensingComponent} />
                 <Route path={["/firmwares/:env?/:tab?"]} component={FirmwaresComponent} />
                 <Route path={["/auth_info/:env?/:tab?"]} component={AuthInfoContoller} />
                 <Route path={["/tools"]} component={ToolsComponent} />
            </>
        )
        }
    </Switch>
</main>
            </div>
        </BrowserRouter>;
    }
}

export default connect((state: RootState): MainComponentProps => {
    return {
        firmwareOnly: state.session?.role === "fw_developer",
        showOnlySoftware: state.session?.role === "sw_reader"
    }
})(MainComponent);
