import {List} from "immutable";
import Environment from "../models/Environment";

export default interface AppBarState {
    readonly title: string;
    readonly environments: List<Environment>;
    readonly selectedEnvironment: Environment | null;
}

export const DefaultAppBarState: AppBarState = {
    title: "ENiQ Cloud Admin",
    environments: List<Environment>(),
    selectedEnvironment: null
};
