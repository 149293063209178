import {SvgIcon} from "@material-ui/core";
import * as React from "react";

export default class LicensingIconComponent extends React.Component<{}, {}> {
    public render(): any {
        return <SvgIcon viewBox="0 0 20 20" {...this.props}>
            <path fill="#000" d="M10 .833L2.5 4.167v5c0 4.625 3.2 8.95 7.5 10 4.3-1.05 7.5-5.375 7.5-10v-5L10 .833zm5.833 8.334c0 3.766-2.483 7.241-5.833 8.275-3.35-1.034-5.833-4.509-5.833-8.275V5.25L10 2.658l5.833 2.592v3.917zm-9.658.491L5 10.833l3.333 3.334L15 7.5l-1.175-1.183-5.492 5.491-2.158-2.15z"/>
        </SvgIcon>;
    }
}
