import {Action, handleActions} from "redux-actions";
import AppBarState, {DefaultAppBarState} from "../states/AppBarState";
import {SELECT_ENV, SET_ENVS, SET_TITLE} from "../actions/appbar";

export const AppBarReducer = handleActions<AppBarState>({
    [SET_TITLE]: (state, action: Action<any>): AppBarState => ({
        title: action.payload,
        environments: state.environments,
        selectedEnvironment: state.selectedEnvironment
    }),
    [SET_ENVS]: (state, action: Action<any>): AppBarState => ({
        title: state.title,
        environments: action.payload,
        selectedEnvironment: action.payload.first(null)
    }),
    [SELECT_ENV]: (state, action: Action<any>): AppBarState => ({
        title: state.title,
        environments: state.environments,
        selectedEnvironment: action.payload
    })
}, DefaultAppBarState);
